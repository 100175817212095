import { useCallback } from "react";
import { pushSegmentAnalyticsTrack } from "utils/segment-analytics/push";
import { getFbp, getFbc } from "utils/segment-analytics/utils/facebookPixel";
import {  extractGSM } from "../utils/segmentUtils";
import { convertCents } from "utils/convertCents";

export const useGoodsamMembershipModalSegment = (gsmData) => {
  const trackSegmentGSModalLoad = useCallback(() => {
    pushSegmentAnalyticsTrack('Checkout Started', {
      cwh_business: 'RV Rentals',
      LoggedIn: true,
      GoodSamMember: false,
      fbc: getFbc(),
      fbp: getFbp()
    });
  }, []);

  const trackSegmentPlanSelectionLoad = useCallback(() => {
    pushSegmentAnalyticsTrack('Checkout Step Viewed', {
      cwh_business: 'RV Rentals',
      LoggedIn: true,
      step: 1,
      step_name: 'Plan Selection',
      GoodSamMember: false,
      fbc: getFbc(),
      fbp: getFbp()
    });
  }, []);

  const trackSegmentPlanSelectionCompleted = useCallback((planId) => {
    const products = extractGSM({ gsmOffers: gsmData.gsmOffers, membershipId: planId });
    pushSegmentAnalyticsTrack('Checkout Step Completed', {
      cwh_business: 'RV Rentals',
      LoggedIn: true,
      step: 1,
      GoodSamMember: false,
      fbc: getFbc(),
      fbp: getFbp(),
      step_name: 'Plan Selection',
      ...(products?.length > 0 && { products })
    });
  }, [gsmData]);

  const trackSegmentPaymentMethodLoad = useCallback((planId) => {
    const products = extractGSM({ gsmOffers: gsmData.gsmOffers, membershipId: planId });
    pushSegmentAnalyticsTrack('Checkout Step Viewed', {
      cwh_business: 'RV Rentals',
      LoggedIn: true,
      step: 2,
      GoodSamMember: false,
      fbc: getFbc(),
      fbp: getFbp(),
      step_name: 'Payment Method',
      ...(products?.length > 0 && { products })
    });
  }, [gsmData]);

  const trackSegmentPaymentMethodCompleted = useCallback((planId) => {
    const products = extractGSM({ gsmOffers: gsmData.gsmOffers, membershipId: planId });
    pushSegmentAnalyticsTrack('Checkout Step Completed', {
      cwh_business: 'RV Rentals',
      LoggedIn: true,
      step: 2,
      GoodSamMember: false,
      fbc: getFbc(),
      fbp: getFbp(),
      step_name: 'Payment Method',
      ...(products?.length > 0 && { products })
    });
  }, [gsmData]);

  const trackSegmentCheckoutLoad = useCallback((planId) => {
    const products = extractGSM({ gsmOffers: gsmData.gsmOffers, membershipId: planId });
    pushSegmentAnalyticsTrack('Checkout Step Viewed', {
      cwh_business: 'RV Rentals',
      LoggedIn: true,
      step: 3,
      GoodSamMember: false,
      fbc: getFbc(),
      fbp: getFbp(),
      step_name: 'Checkout',
      ...(products?.length > 0 && { products })
    });
  }, [gsmData]);

  const trackSegmentOrderCompleted = useCallback((planId) => {
    const products = extractGSM({ gsmOffers: gsmData.gsmOffers, membershipId: planId });
    pushSegmentAnalyticsTrack('Order Completed', {
      cwh_business: 'RV Rentals',
      LoggedIn: true,
      GoodSamMember: false,
      fbc: getFbc(),
      fbp: getFbp(),
      order_id: gsmData.membershipId,
      revenue: Number(convertCents(gsmData?.priceTotal?.cents_total)),
      ...(products?.length > 0 && { products })
    });
  }, [gsmData]);

  const trackSegmentStep = useCallback((step, planId) => {
    switch (step) {
    case 0:
      trackSegmentPlanSelectionLoad();
      break;
    case 1:
      trackSegmentPaymentMethodLoad(planId);
      break;
    case 2:
      trackSegmentCheckoutLoad(planId);
      break;
    default:
      return null;
    }
  }, [trackSegmentCheckoutLoad, trackSegmentPaymentMethodLoad, trackSegmentPlanSelectionLoad]);

  const trackSegmentNextStep = useCallback((step, planId) => {
    switch (step) {
    case 0:
      trackSegmentPlanSelectionLoad();
      break;
    case 1:
      trackSegmentPlanSelectionCompleted(planId);
      trackSegmentPaymentMethodLoad(planId);
      break;
    case 2:
      trackSegmentPaymentMethodCompleted(planId);
      trackSegmentCheckoutLoad(planId);
      break;
    default:
      return null;
    }
  }, [trackSegmentCheckoutLoad, trackSegmentPaymentMethodCompleted, trackSegmentPaymentMethodLoad, trackSegmentPlanSelectionCompleted, trackSegmentPlanSelectionLoad]);

  return {
    trackSegmentGSModalLoad,
    trackSegmentPlanSelectionLoad,
    trackSegmentPlanSelectionCompleted,
    trackSegmentPaymentMethodLoad,
    trackSegmentPaymentMethodCompleted,
    trackSegmentCheckoutLoad,
    trackSegmentOrderCompleted,
    trackSegmentStep,
    trackSegmentNextStep
  };
};
