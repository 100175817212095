import React, { useCallback, useEffect, useRef } from "react";
import { ListingContext } from "../../ListingContext";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { rvAmenitiesMutation } from "../../mutations/rvAmenitiesMutation";
import {
  defaultTo,
  filter,
  lensProp,
  over,
  pick,
  pipe,
  prop,
  map,
  path
} from "ramda";
import { AmenitiesContentLoader } from "./AmenitiesContentLoader";
import {
  extractServerErrors,
  extractSuccess
} from "../../../../utils/extractErrors";
import { SpinnerBack } from "../../../Spinner/Spinner";
import { ContentLoader } from "components/ContentSection";
import { amenitiesSectionContentQuery } from "components/Listing/queries/listingSectionContentQuery";
import { pushGoogleAnalytics } from "utils/google-analytics/push";
import { handleListingSegment } from "components/Listing/utils/handleListingSegment";
import { useListingSegment } from "components/Listing/hooks/useListingSegment";

const processInitialData = pipe(
  over(lensProp("amenities"), (arr) => {
    return arr.reduce((prev, key) => ({ ...prev, [key.id]: true }), {});
  }),
  prop("amenities")
);
const normalizeValues = (values) =>
  Object.keys(values)
    .filter((key) => values[key])
    .map((v) => parseInt(v, 10));
const extractRvUpdateSuccess = extractSuccess("rv_update");
const extractRv = path(["data", "rv_update", "rv"]);
const extractRvUpdateErrors = extractServerErrors("rv_update");
const filterPublishErrorsBySection = pipe(
  defaultTo([]),
  filter((v) => v.section === "amenities"),
  map(pick(["field", "message"]))
);
const cleanSectionErrors = pipe(
  defaultTo([]),
  filter((v) => v.section !== "amenities")
);

const AmenitiesDataProvider = ({
  context,
  updateContext,
  currStep,
  prevStep,
  nextStep
}) => {
  const { id: initialId } = context.initialData;
  const isSegmentSent = useRef(null);
  const history = useHistory();
  const [action, result] = useMutation(rvAmenitiesMutation);
  const { trackSegmentAmenitiesLoad, trackSegmentAmenitiesCompleted } = useListingSegment(context);

  const handleSave = useCallback(
    async (values, { toNextStep = true } = {}) => {
      const response = await action({
        variables: {
          amenities: normalizeValues(values),
          id: initialId
        }
      });

      if (extractRvUpdateSuccess(response)) {
        const rv = extractRv(response);
        trackSegmentAmenitiesCompleted(context?.initialData, rv, context?.userId);
        pushGoogleAnalytics("listVehicleAmenities");
        updateContext({
          initialData: {
            ...context.initialData,
            ...rv
          },
          errors: cleanSectionErrors(context.errors)
        });

        if (nextStep && toNextStep) {
          history.push(`/listing/${nextStep.path}?id=${rv.id}`, {
            prevStep: currStep
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      action,
      initialId,
      updateContext,
      context.initialData,
      context.errors,
      nextStep,
      history,
      currStep,
      context?.userId
    ]
  );

  const handleBack = useCallback(() => {
    if (prevStep) {
      history.push(`/listing/${prevStep.path}?id=${initialId}`);
    }
  }, [history, initialId, prevStep]);

  const handleLater = useCallback(() => {
    if (nextStep) {
      history.push(`/listing/${nextStep.path}?id=${initialId}`);
    }
  }, [history, initialId, nextStep]);

  const updateContextSaveHandler = useCallback(
    (cb) => {
      updateContext({
        handleSave: cb
      });
    },
    [updateContext]
  );

  useEffect(() => {
    if (!isSegmentSent.current && context?.userId) {
      trackSegmentAmenitiesLoad(context?.userId);
      isSegmentSent.current = true;
    }
  }, [trackSegmentAmenitiesLoad, context?.userId]);

  const publishErrors = filterPublishErrorsBySection(context.errors);

  const stepErrors = extractRvUpdateErrors(result);

  return (
    <>
      <AmenitiesContentLoader
        initialData={processInitialData(context.initialData)}
        onBack={handleBack}
        onLater={handleLater}
        onSave={handleSave}
        loading={result.loading}
        updateContextSaveHandler={updateContextSaveHandler}
        listingPublished={context.isListingPublished}
        requestErrors={publishErrors.length ? publishErrors : stepErrors}
        prevStep={prevStep}
        nextStep={nextStep}
      />
      {result.loading && <SpinnerBack />}
    </>
  );
};
function everyKey(keys) {
  return (data) => keys.every((k) => data[k]);
}

const amenitiesSectionContentPredicate = everyKey(["amenitiesSectionContent"]);

export const Amenities = ({ currStep, prevStep, nextStep }) => (
  <ListingContext.Consumer>
    {(context) => (
      <ContentLoader
        query={amenitiesSectionContentQuery}
        predicate={amenitiesSectionContentPredicate}
        render={() => (
          <AmenitiesDataProvider
            context={context[0]}
            updateContext={context[1]}
            currStep={currStep}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        )}
      />
    )}
  </ListingContext.Consumer>
);
