import React, { useEffect, useCallback, useState } from "react";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { createSelector } from "reselect";
import { path, pipe } from "ramda";
import { rvQuery } from "./queries/rvQuery";
import { featureFlagsQuery } from "queries/featureFlagsQuery";
import { garageRvMutation } from "./mutations/garageRvMutation";
import { ListingContext } from "./ListingContext";
import { Listing } from "./Listing";
import { useIdFromLocation } from "../../hooks/useIdFromLocation";
import { useGarageIdFromLocation } from "../../hooks/useGarageIdFromLocation";
import { membershipDetailsQuery } from "queries/membershipDetailsQuery";
import { rvAdditionalInsuranceQuery } from "components/Listing/steps/AdditionalInsurance/queries/rvAdditionalInsuranceQuery";
import { SpinnerBack } from "../Spinner/Spinner";
import { isGoodSamMember } from "utils/isGoodSamMember";
import { useAddSegmentContext } from "hooks/useAddSegmentContext";
import { rvAmenitiesQuery } from "components/Listing/steps/Amenities/queries/rvAmenitiesQuery";
import { userIdQuery } from "queries/userIdQuery";

const defaultData = {};
const RESET_DATA = {
  initialData: {},
  isDataLoaded: true,
  isRvCreated: false,
  isListingPublished: false,
  isListingModerated: false,
  errors: []
};

function ensureData(data) {
  return data || defaultData;
}

/* function ensureInt(id) {
  const rvId = parseInt(id, 10);

  if (!Number.isNaN(rvId)) {
    return rvId;
  }

  return undefined;
} */

const selector = createSelector(
  (state) => state,
  (state, update) => update,
  (state, update, refetch) => refetch,
  (state, update, refetch) => [state, update, refetch]
);

const extractStatus = pipe(path(["status", "key"]));

function useRvState(initialState) {
  const [state, setState] = useState(initialState);

  const update = useCallback(
    (patch) => {
      if (patch && Object.keys(patch).length > 0) {
        setState((prevState) => ({
          ...prevState,
          ...patch
        }));
      }
    },
    [setState]
  );
  return [state, update];
}

export const ListingContainer = () => {
  useAddSegmentContext();
  const id = useIdFromLocation();
  const garageId = useGarageIdFromLocation();

  const [state, update] = useRvState(null);
  const [load, { data, error, loading, refetch }] = useLazyQuery(rvQuery);
  const [loadGarage, {
    data: dataGarage,
    error: errorGarage,
    loading: loadingGarage
  }] = useMutation(garageRvMutation);

  const { data: userQueryData } = useQuery(
    userIdQuery,
    {}
  );

  const { data: featureFlagsData } = useQuery(featureFlagsQuery, {
    fetchPolicy: "no-cache"
  });

  const { data: membershipDetailsData } = useQuery(membershipDetailsQuery, {
    fetchPolicy: "no-cache"
  });

  const { data: rvAmenitiesData } = useQuery(rvAmenitiesQuery, {
    fetchPolicy: "no-cache"
  });

  const [
    rvAdditionalInsuranceLoad, { data: rvAdditionalInsuranceData, loading: rvAdditionalInsuranceLoading }
  ] = useLazyQuery(rvAdditionalInsuranceQuery,{
    fetchPolicy: "no-cache"
  });

  const awaitingData = id && !data && !error;
  const awaitingGarageData = garageId && !dataGarage && !errorGarage;

  useEffect(() => {
    document.documentElement.style.setProperty('--top-nav-chat-display', 'none');
    return () => {
      document.documentElement.style.removeProperty('--top-nav-chat-display');
    };
  }, []);

  useEffect(() => {
    if (id) {
      rvAdditionalInsuranceLoad({
        variables: {
          rv: id
        }
      });
      load({
        variables: {
          id
        }
      });
    }

    if (garageId) {
      loadGarage({
        variables: {
          rv_garage_id: garageId
        }
      });
    }
  }, [load, id, loadGarage, garageId, rvAdditionalInsuranceLoad]);

  useEffect(() => {
    if (awaitingData || awaitingGarageData) return undefined;

    const initialData = ensureData(data?.rv || dataGarage?.rv_create_by_garage?.rv);
    const isRvCreated = initialData.id !== undefined;

    let isDataLoaded = true;

    if (dataGarage?.rv_create_by_garage?.rv) {
      isDataLoaded = isRvCreated
        ? dataGarage !== undefined || errorGarage !== undefined
        : true;
    }

    if (data?.rv) {
      isDataLoaded = isRvCreated
        ? data !== undefined || error !== undefined
        : true;
    }
    const status = extractStatus(initialData);
    const isListingPublished =
      status !== undefined ? !(status === "1" || status === "2") : false;
    const isListingModerated = status === "4";
    const currentRvDeleted = isRvCreated && status === "0";

    if (currentRvDeleted) {
      update(RESET_DATA);
      return null;
    }

    update({
      isFooterVisible: !isListingPublished,
      isGarage: featureFlagsData?.feature_flags.includes('garage_integration'),
      initialData,
      isDataLoaded,
      isRvCreated,
      isListingPublished,
      isListingModerated,
      userId: userQueryData?.user?.id,
      insurances: rvAdditionalInsuranceData?.rv_additional_insurance?.insurances || [],
      isGoodSamMember: isGoodSamMember(membershipDetailsData?.membership_details),
      rvAmenities: rvAmenitiesData?.rvAmenities
    });
  }, [
    awaitingData,
    awaitingGarageData,
    data,
    dataGarage,
    error,
    errorGarage,
    update,
    featureFlagsData,
    rvAdditionalInsuranceData?.rv_additional_insurance?.insurances,
    membershipDetailsData?.membership_details,
    rvAmenitiesData,
    userQueryData?.user?.id
  ]);

  // if ((id && !state) || awaitingData) {
  //   return null;
  // }

  return (
    <ListingContext.Provider value={selector(state, update, refetch)}>
      <Listing />
      {(loading || loadingGarage) && <SpinnerBack />}
    </ListingContext.Provider>
  );
};
