import { gql } from "@apollo/client";
import { fragments } from "../queries/rvQuery";

export const otherFeesMutation = gql`
  ${fragments.generalInfo},
  ${fragments.rvDetails},
  ${fragments.listingDetails},
  ${fragments.images},
  ${fragments.amenities},
  ${fragments.location},
  ${fragments.rentalFees},
  ${fragments.otherFeesAndAddons},
  ${fragments.publishedListingValues},
  ${fragments.availability},
  ${fragments.availabilityCalender}

  mutation rvUpdate(
    $id: Int!
    $limited_daily_mileage: Boolean!
    $miles_included: Int
    $mileage_overage_rate: Float
    $generator_not_applicable: Boolean
    $limited_generator_hours: Boolean!
    $generator_hours_included: Int
    $generator_overage_rate: Float
    $cleaning_fee: Float
    $disposal_fee: Float
    $add_ons: [AddOnInput]!
  ) {
    rv_update(
      step: "add_ons"
      id: $id
      limited_daily_mileage: $limited_daily_mileage
      miles_included: $miles_included
      mileage_overage_rate: $mileage_overage_rate
      generator_not_applicable: $generator_not_applicable
      limited_generator_hours: $limited_generator_hours
      generator_hours_included: $generator_hours_included
      generator_overage_rate: $generator_overage_rate
      cleaning_fee: $cleaning_fee
      disposal_fee: $disposal_fee
      add_ons: $add_ons
    ) {
      result {
        success
        errors {
          field
          message
        }
      }
      rv {
        id
        fuel_type {
          key
          value
        }
        listing_steps_completed
        current_miles
        minimum_daily_rate {
          cents_total
        }
        amenities {
          id
          name
          icon
        }
        fields_awaiting_moderation
        owner {
          id
          good_sam_membership_validation {
            key
            value
          }
        }
        class
        make
        model
        year
        length {
          feet_as_part
          inches_total
        }
        price {
          user_friendly
        }
        sleeps
        slide_outs
        weight
        stated_value {
          cents_total
        }
        daily_rate {
          dollars_as_part
          cents_total
        }
        location {
          full_address
          short_address
        }
        status {
          key
          value
        }
        ...GeneralInfo,
        ...RvDetails,
        ...ListingDetails,
        ...Images,
        ...Amenities,
        ...Location,
        ...RentalFees,
        ...OtherFeesAndAddons
        ...PublishedListingValues
        ...Availability,
        ...AvailabilityCalender
      }
    }
  }
`;
