import { useCallback } from "react";
import { pushSegmentAnalyticsTrack } from "utils/segment-analytics/push";
import { getFbp } from "utils/segment-analytics/utils/facebookPixel";

export const useRentalSegment = () => {
  const trackSegmentMyRentalsSearchRV = useCallback(() => {
    pushSegmentAnalyticsTrack('Products Searched', {
      location_on_page: "my profile",
      fbp: getFbp(),
      cwh_business: "RV Rentals"
    });
  }, []);

  return {
    trackSegmentMyRentalsSearchRV
  };
};
