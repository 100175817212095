import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  lensProp,
  over,
  path,
  pipe,
  prop,
  defaultTo,
  pick,
  map,
  filter,
  mapObjIndexed
} from "ramda";
import { useMutation } from "@apollo/client";
import { FeesForm } from "./FeesForm";
import { ListingContext } from "../../ListingContext";
import { feesMutation } from "../../mutations/feesMutation";
import { filterInitialData } from "../filterInitialData";
import {
  extractServerErrors,
  extractSuccess,
  getErrorsAndSuccess
} from "../../../../utils/extractErrors";
import { SpinnerBack } from "../../../Spinner/Spinner";
import { ContentLoader } from "components/ContentSection";
import { rentalFeesSectionContentQuery } from "components/Listing/queries/listingSectionContentQuery";
import { pushGoogleAnalytics } from "utils/google-analytics/push";
import { handleListingSegment } from "components/Listing/utils/handleListingSegment";
import { useListingSegment } from "components/Listing/hooks/useListingSegment";

const rvUpdateMutationEAS = getErrorsAndSuccess("rv_update");
const extractRvUpdateErrors = extractServerErrors("rv_update");
const extractRvUpdateSuccess = extractSuccess("rv_update");
const extractRv = path(["data", "rv_update", "rv"]);
const filterInitialListingDetails = filterInitialData([
  "minimum_duration",
  "daily_rate",
  "discounts",
  "security_deposit",
  "instant_rentals__days_before_the_rental",
  "instant_rentals"
]);
const defaultToArr = defaultTo([]);
const defaultToUnlimited = defaultTo("Not limited");
const defaultToZero = defaultTo(0);
const processInitialData = pipe(
  over(
    lensProp("daily_rate"),
    pipe(prop("cents_total"), defaultToZero, (v) => Math.round(v / 100))
  ),
  over(
    lensProp("discounts"),
    pipe(
      defaultToArr,
      map(pick(["from", "till", "discount"])),
      map(over(lensProp("till"), defaultToUnlimited))
    )
  ),
  over(
    lensProp("security_deposit"),
    pipe(prop("cents_total"), defaultToZero, (v) => Math.round(v / 100))
  ),
  over(lensProp("instant_rentals__days_before_the_rental"), (v) => Number(parseInt(v, 10))),
  over(lensProp("instant_rentals"), (v) => v),
  filterInitialListingDetails
);
const extractContent = pipe(
  over(lensProp("suggested_daily_rate"), path(["user_friendly"])),
  filterInitialData(["suggested_daily_rate"])
);
const normalizeValues = pipe(
  over(lensProp("minimum_days_before_instant_rental"), (v) => Number(parseInt(v, 10))),
  over(lensProp("instant_rentals"), (v) => v),
  over(lensProp("minimum_duration"), (v) => parseInt(v, 10)),
  over(lensProp("daily_rate"), (v) => parseInt(v, 10)),
  over(
    lensProp("discounts"),
    pipe(
      defaultToArr,
      map(pick(["from", "discount"])),
      map(mapObjIndexed((v) => parseInt(v, 10)))
    )
  ),
  over(lensProp("security_deposit"), (v) => parseInt(v, 10))
);
const filterPublishErrorsBySection = pipe(
  defaultTo([]),
  filter((v) => v.section === "fees"),
  map(pick(["field", "message"]))
);
const cleanSectionErrors = pipe(
  defaultTo([]),
  filter((v) => v.section !== "fees")
);

const FeesDataProvider = ({
  context,
  updateContext,
  currStep,
  prevStep,
  nextStep
}) => {
  const { id: initialId } = context.initialData;
  const isSegmentSent = useRef(null);
  const history = useHistory();
  const [action, result] = useMutation(feesMutation);
  const [requestErrors, setRequestErrors] = useState([]);
  const { trackSegmentFeesLoad, trackSegmentFeesCompleted } = useListingSegment(context);

  const handleSave = useCallback(
    async (values, { toNextStep = true } = {}) => {
      const normalizeVs =  { ...normalizeValues(values) };

      const { bank_account_number, instant_rentals__days_before_the_rental, ...newNormalizeVs } = normalizeVs;

      const response = await action({
        variables: {
          ...newNormalizeVs,
          id: initialId
        }
      });

      if (extractRvUpdateSuccess(response)) {
        const rv = extractRv(response);

        if (rv) {
          pushGoogleAnalytics("listVehicleFees", {
            dayMin: rv?.minimum_duration,
            dailyRate: rv?.daily_rate?.user_friendly,
            securityDeposit: rv?.security_deposit?.user_friendly
          });
          trackSegmentFeesCompleted(context?.initialData, rv, context?.userId);
          updateContext({
            initialData: {
              ...context.initialData,
              ...rv
            },
            errors: cleanSectionErrors(context.errors)
          });

          if (nextStep && toNextStep) {
            history.push(`/listing/${nextStep.path}?id=${rv.id}`, {
              prevStep: currStep
            });
          }
        }
      } else if(rvUpdateMutationEAS.isErrors(response)) {
        setRequestErrors(rvUpdateMutationEAS.errors(response));
      }

      return response;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      action,
      initialId,
      updateContext,
      context.initialData,
      context.errors,
      nextStep,
      history,
      currStep,
      context?.userId
    ]
  );

  const handleBack = useCallback(() => {
    if (prevStep) {
      history.push(`/listing/${prevStep.path}?id=${initialId}`);
    }
  }, [history, initialId, prevStep]);

  const handleLater = useCallback(() => {
    if (nextStep) {
      history.push(`/listing/${nextStep.path}?id=${initialId}`);
    }
  }, [history, initialId, nextStep]);


  const updateContextSaveHandler = useCallback(
    (cb) => {
      updateContext({
        handleSave: cb
      });
    },
    [updateContext]
  );

  const onResetError = useCallback(() => {
    updateContext({
      errors:[]
    });
    setRequestErrors([]);
  }, [updateContext]);

  useEffect(() => {
    if (!isSegmentSent.current && context?.userId) {
      trackSegmentFeesLoad(context?.userId);
      isSegmentSent.current = true;
    }
  }, [trackSegmentFeesLoad, context?.userId]);

  useEffect(() => {
    if (context?.errors?.length > 0) {
      setRequestErrors(filterPublishErrorsBySection(context.errors));
    } else {
      setRequestErrors([]);
    }
  }, [context?.errors]);

  return (
    <>
      <FeesForm
        initialData={processInitialData(context.initialData)}
        contentData={extractContent(context.initialData)}
        requestErrors={requestErrors}
        onResetError={onResetError}
        onBack={handleBack}
        onLater={handleLater}
        onSave={handleSave}
        updateContextSaveHandler={updateContextSaveHandler}
        listingPublished={context.isListingPublished}
        isFooterVisible={context?.isFooterVisible}
        loading={result.loading}
        prevStep={prevStep}
        nextStep={nextStep}
      />
      {result.loading && <SpinnerBack />}
    </>
  );
};
function everyKey(keys) {
  return (data) => keys.every((k) => data[k]);
}

const rentalFeesSectionContentPredicate = everyKey([
  "rentalFeesSectionContent"
]);

export const Fees = ({ currStep, prevStep, nextStep }) => {
  return (
    <ListingContext.Consumer>
      {(context) => (
        <ContentLoader
          query={rentalFeesSectionContentQuery}
          predicate={rentalFeesSectionContentPredicate}
          render={() => (
            <FeesDataProvider
              context={context[0]}
              updateContext={context[1]}
              currStep={currStep}
              prevStep={prevStep}
              nextStep={nextStep}
            />
          )}
        />
      )}
    </ListingContext.Consumer>
  );
};
