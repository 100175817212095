import React from "react";
import PropTypes from "prop-types";
import { PROCESSES } from "utils/constants/processes";
import { AuthChecker } from "router/withAuthCheck";
import VerificationStepsModals from "components/VerificationStepsModals/VerificationStepsModals";
import { VERIFICATION_STEPS } from "components/VerificationStepsModals/constants";
import BookingModalContent from "./BookingModalContent";
import BookingModalProvider from "./BookingModalProvider";
import { BookingSession } from "./types";

export const BOOKING_CONTINUE_HASH = "#continue-booking";

const BookingModal = (props) => {
  const {
    rvId,
    session,
    bookingBoxSegment,
    rentalType,
    rentalTypeValue,
    show,
    onReserve,
    onClose,
    onChangePickUp,
    onChangeCalendar,
    isIMGlobalIntegrationIsEnabled,
    isInstantRental,
    rvLocationData,
    onRefetchRvInitialData,
    isRefetchCalculatePrice,
    setIsRefetchCalculatePrice,
    isRefetchBookingModalData,
    setIsRefetchBookingModalData
  } = props;

  return (
    <VerificationStepsModals
      onClose={onClose}
      show={show}
      process={PROCESSES.BOOKING}
      verifyList={[VERIFICATION_STEPS.email, VERIFICATION_STEPS.phone]}
    >
      {() => (
        <BookingModalProvider
          rvId={rvId}
          rentalType={rentalType}
          rentalTypeValue={rentalTypeValue}
          session={session}
          onReserve={onReserve}
          onChangePickUp={onChangePickUp}
          onChangeCalendar={onChangeCalendar}
          isIMGlobalIntegrationIsEnabled={isIMGlobalIntegrationIsEnabled}
          isInstantRental={isInstantRental}
          rvLocationData={rvLocationData}
          onRefetchRvInitialData={onRefetchRvInitialData}
          isRefetchCalculatePrice={isRefetchCalculatePrice}
          setIsRefetchCalculatePrice={setIsRefetchCalculatePrice}
          isRefetchBookingModalData={isRefetchBookingModalData}
          setIsRefetchBookingModalData={setIsRefetchBookingModalData}
          bookingBoxSegment={bookingBoxSegment}
        >
          <BookingModalContent show={show} onClose={onClose} />
        </BookingModalProvider>
      )}
    </VerificationStepsModals>
  );
};

export const BookingModalWithAuthCheck = (props) => {
  return <AuthChecker>{() => <BookingModal {...props} />}</AuthChecker>;
};

const Props = {
  rvId: PropTypes.number.isRequired,
  session: BookingSession.isRequired,
  show: PropTypes.bool.isRequired,
  onReserve: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangePickUp: PropTypes.func,
  onChangeCalendar: PropTypes.func
};

BookingModal.propTypes = Props;
BookingModalWithAuthCheck.propTypes = Props;

export default BookingModal;
