import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import min from "lodash/min";
import max from "lodash/max";
import { pathOr } from "ramda";
import moment from "moment";
import PropTypes from "prop-types";
import { BookingModalContext } from "./BookingModalContext";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { STEPS, useBookingModalSteps } from "./steps";
import { BookingSession } from "./types";
import { initialDataQuery } from "processes/Booking/components/BookingModal/queries/initialDataQuery";
import { additionalInsuranceList } from "queries/additionalInsuranceList";
import { calculatePriceQuery } from "./queries/calculatePrice";
import { rentalInterruptionInsuranceContent } from "processes/Booking/components/BookingModal/queries/rentalInterruptionInsuranceContent";
import { reserveQuery } from "./mutations/reserve";
import { extractServerErrors, extractSuccess, getErrorsAndSuccess } from "utils/extractErrors";
import { useRequestErrors } from "hooks/useRequestErrors";
import { pushGoogleAnalytics } from "utils/google-analytics/push";
import { pushSegmentAnalyticsIdentify } from "utils/segment-analytics/push";
import { useSegmentAnalyticsIdentify } from "utils/segment-analytics/hooks/useSegmentAnalyticsIdentify";
import {
  getInitialAdditionalInsuranceData,
  mergeAdditionalInsuranceData,
  getTotalPackagesIds,
  isNextStep,
  addAdditionalInsurance,
  removeExtraPackagesId,
  setInitialDefaultTags
} from "components/AdditionalInsuranceList/utils";
import { mergeAddons } from "processes/Booking/components/BookingModal/steps/AddOns/utils";
import { RESERVE_ERRORS } from "constants/errorCodes";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { ADD_INSURANCE_LIST_MODES, ADD_INSURANCE_DEFAULT_TAGS } from "components/AdditionalInsuranceList/constants";
import { linksContentQuery } from "queries/contentQuery";
import { useBookingStepSegment } from "processes/Booking/components/BookingModal/hooks";
import { trackSegmentBooking } from "processes/Booking/utils/trackSegmentBooking";
import { useMembershipOffers } from "components/GoodsamMembership/query";
import { useAddSegmentContext } from "hooks/useAddSegmentContext";

const extractPricesErrors = extractServerErrors("calculate_price");
const extractReserveErrors = extractServerErrors("reserve");
const reserveEAS = getErrorsAndSuccess("reserve");
const addInsuranceListEAS = getErrorsAndSuccess("additional_insurance_list");
const rvEAS = getErrorsAndSuccess("rv");

const BookingModalProvider = (props) => {
  const {
    rvId,
    session,
    rentalType,
    rentalTypeValue,
    onReserve,
    onChangePickUp,
    onChangeCalendar,
    isIMGlobalIntegrationIsEnabled,
    isInstantRental,
    rvLocationData,
    onRefetchRvInitialData,
    isRefetchCalculatePrice,
    setIsRefetchCalculatePrice,
    isRefetchBookingModalData,
    setIsRefetchBookingModalData,
    bookingBoxSegment
  } = props;

  const [isModalHidden, setIsModalHidden] = useState(false);
  const [showAsideRvInfoDetails, setShowAsideRvInfoDetails] = useState(false);
  const [membershipId, setMembershipId] = useState(session.membership);
  const [isMembershipValidation, setIsMembershipValidation] = useState(session.isClientGoodSamMember);
  const [insuranceId, setInsuranceId] = useState(null);
  const [isInterruptionVisible, setIsInterruptionVisible] = useState(true);
  const [isTII, setIsTII] = useState(false);
  const [isNoSplitPayments, setIsNoSplitPayments] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [stepIndex, setStepIndex] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [addOns, setAddOns] = useState(session.addons || []);
  const addOnsIds = useMemo(() => addOns.map((i) => i.id), [addOns]);
  const [addInsuranceList, setAddInsuranceList] = useState({});
  const [selectedPackagesAdditionalInsurance, setSelectedPackagesAdditionalInsurance] = useState({
    insurancesIds: [],
    packagesIds: []
  });
  const [isLoadingCalculatePriceRefetch, setIsLoadingCalculatePriceRefetch] = useState(false);
  const [isRefetchBookingModalDataLoading, setIsRefetchBookingModalDataLoading] = useState(false);
  const [error, setError] = useState("");
  const [tiiDefaultTags, setTiiDefaultTags] = useState(setInitialDefaultTags());
  const [isClearTii, setIsClearTii] = useState(false);
  const hasAddInsuranceListDataChanged = useRef(false);
  const firstSegmentLoaded = useRef(false);
  const isSegmentContextLoaded = useRef(false);
  const { segmentDataUserId } = useAddSegmentContext();

  useEffect(() => {
    setInsuranceId(session.insurance);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (segmentDataUserId && !isSegmentContextLoaded.current) {
      trackSegmentBooking('Checkout Started', bookingBoxSegment);
      isSegmentContextLoaded.current = true;
    }
  }, [bookingBoxSegment, segmentDataUserId, isSegmentContextLoaded]);

  const [sso, identifyData] = useSegmentAnalyticsIdentify();

  const addInsuranceListVariables = useMemo(() => {
    return {
      rv: rvId,
      departure_date: moment(session.departureDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
      return_date: moment(session.returnDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
      rental_type: rentalType
    };
  }, [rentalType, rvId, session.departureDate, session.returnDate]);

  /*
   * RV Query
   */

  const { loading, data, refetch: refetchInitialData } = useQuery(initialDataQuery, {
    variables: {
      id: rvId,
      for_listing: false
    },
    fetchPolicy: "no-cache"
  });

  const { data: interruptionInsuranceData, loading: interruptionInsuranceDataLoading } = useQuery(rentalInterruptionInsuranceContent, {
    variables: {
      section: "insurance_coverage_description"
    },
    fetchPolicy: "no-cache"
  });

  const { loading: additionalInsuranceListLoading, data: additionalInsuranceListData, refetch: refetchAdditionalInsuranceList } = useQuery(additionalInsuranceList, {
    variables: addInsuranceListVariables,
    fetchPolicy: "no-cache",
    skip: hasAddInsuranceListDataChanged?.current
  });

  const { loading: linksContentQueryLoading, data: linksContentQueryData } = useQuery(linksContentQuery, {
    fetchPolicy: "no-cache"
  });

  const { loading: membershipOffersLoading, data: membershipOffersData } = useMembershipOffers();

  const interruptionInsuranceContent = pathOr(
    "",
    ["rentalInterruptionInsuranceContent", 'text', '0', 'value'],
    interruptionInsuranceData
  );

  const rvData = data?.rv;
  const user = data?.user;
  const gsmOffers = membershipOffersData?.good_sam_membership_offers;

  /*
   * Steps
   */

  const {
    steps,
    currentStepIndex,
    onClickStep,
    onClickStepById,
    onNextStep,
    onPrevStep
  } = useBookingModalSteps(rvData, addInsuranceList, isTII);

  const currentStep = steps[stepIndex];

  const setStepCompleted = useCallback((step) => {
    setCompletedSteps((p) => [...p, step.id]);
  }, []);

  const _setStepIndex = useCallback(
    (index) => {
      setStepIndex(min([max([index, 0]), steps.length - 1]));
    },
    [steps]
  );

  /*
   * Prices calculation
   */
  const pricesVariables = useMemo(() => {
    return {
      id: rvId,
      departure_date: session.departureDate,
      return_date: session.returnDate,
      delivery_location: session.deliveryLocation,
      coupon_code: coupon?.code,
      insurance_package: insuranceId,
      add_ons: addOnsIds,
      good_sam_membership_package: membershipId,
      is_trip_interruption_insurance: isInterruptionVisible,
      no_split_payments: isNoSplitPayments,
      specific_delivery_location_id: session.specific_delivery_location_id,
      rental_type: session.rentalType,
      additional_insurances: removeExtraPackagesId(getTotalPackagesIds(selectedPackagesAdditionalInsurance, addInsuranceList || {}))
    };
  }, [rvId, session.departureDate, session.returnDate, session.deliveryLocation, session.specific_delivery_location_id, session.rentalType, coupon?.code, insuranceId, addOnsIds, membershipId, isInterruptionVisible, isNoSplitPayments, selectedPackagesAdditionalInsurance, addInsuranceList]);

  const [calculatePriceLoad, { data: pricesData, loading: pricesLoading, refetch: calculatePriceRefetch }] = useLazyQuery(calculatePriceQuery, {
    fetchPolicy: "no-cache"
  });

  const pricesErrors = extractPricesErrors({ data: pricesData });
  const [pricesError] = useRequestErrors(pricesErrors);
  const prices = useMemo(() => {
    return pricesData?.calculate_price?.price;
  }, [pricesData]);

  const price_form_detailed = pricesData?.calculate_price?.price_form_detailed;

  const {
    trackSegmentAddonsLoad,
    trackSegmentAddonsCompleted,
    trackSegmentAdditionalInsuranceLoad,
    trackSegmentAdditionalInsuranceCompleted,
    trackSegmentPaymentMethodLoad,
    trackSegmentPaymentMethodCompleted,
    trackSegmentPaymentOptionsCompleted,
    trackSegmentConfirmAndPay,
    trackSegmentNextStep,
    trackSegmentStep
  } = useBookingStepSegment(
    bookingBoxSegment,
    { addOns, addOnsIds },
    { data: addInsuranceList, selected: selectedPackagesAdditionalInsurance },
    { gsmOffers, membershipId },
    prices
  );

  useEffect(() => {
    if (additionalInsuranceListData?.additional_insurance_list?.insurances && !hasAddInsuranceListDataChanged.current) {

      calculatePriceLoad({
        variables: {
          ...pricesVariables,
          additional_insurances: getTotalPackagesIds(getInitialAdditionalInsuranceData(additionalInsuranceListData?.additional_insurance_list), additionalInsuranceListData?.additional_insurance_list)
        }
      });
      if (prices) {
        if (prices?.trip_interruption_insurance && linksContentQueryData?.linksContent?.buttons) {
          hasAddInsuranceListDataChanged.current = true;
          setSelectedPackagesAdditionalInsurance(getInitialAdditionalInsuranceData(addAdditionalInsurance(additionalInsuranceListData?.additional_insurance_list, prices?.trip_interruption_insurance?.cents_total, linksContentQueryData?.linksContent?.buttons), ADD_INSURANCE_LIST_MODES.USING));
          setAddInsuranceList(addAdditionalInsurance(additionalInsuranceListData?.additional_insurance_list, prices?.trip_interruption_insurance?.cents_total, linksContentQueryData?.linksContent?.buttons));
          setIsInterruptionVisible(false);
        } else {
          hasAddInsuranceListDataChanged.current = true;
          setSelectedPackagesAdditionalInsurance(getInitialAdditionalInsuranceData(ADD_INSURANCE_LIST_MODES.USING));
          setAddInsuranceList(additionalInsuranceListData?.additional_insurance_list);
          setIsInterruptionVisible(false);
        }
      }
    }
  }, [additionalInsuranceListData?.additional_insurance_list, calculatePriceLoad, calculatePriceRefetch, prices, pricesVariables, linksContentQueryData?.linksContent?.buttons]);

  const onRefetchAdditionalInsuranceList = useCallback(
    async () => {
      setIsLoadingCalculatePriceRefetch(true);
      setIsTII(false);

      const responseCalculatePriceData = await calculatePriceRefetch(pricesVariables);
      const responseAddInsuranceList = await refetchAdditionalInsuranceList(addInsuranceListVariables);

      const tiiPrice = responseCalculatePriceData?.data?.calculate_price?.price?.trip_interruption_insurance?.cents_total;

      if (addInsuranceListEAS.isQuerySuccess(responseAddInsuranceList) && linksContentQueryData?.linksContent?.buttons) {
        setSelectedPackagesAdditionalInsurance(mergeAdditionalInsuranceData(selectedPackagesAdditionalInsurance, addAdditionalInsurance(addInsuranceListEAS.getQueryData(responseAddInsuranceList), tiiPrice, linksContentQueryData?.linksContent?.buttons)));
        setAddInsuranceList(addAdditionalInsurance(addInsuranceListEAS.getQueryData(responseAddInsuranceList), tiiPrice, linksContentQueryData?.linksContent?.buttons));
        setIsTII(Boolean(tiiPrice));
      }

      setIsLoadingCalculatePriceRefetch(false);
    },
    [addInsuranceListVariables, calculatePriceRefetch, pricesVariables, refetchAdditionalInsuranceList, selectedPackagesAdditionalInsurance, linksContentQueryData?.linksContent?.buttons]
  );

  const onRefetchRefetchInitialData = useCallback(
    async () => {
      const responseInitialData = await refetchInitialData({
        id: rvId,
        for_listing: false
      });

      if (rvEAS.isQuerySuccess(responseInitialData)) {
        const { add_ons } = rvEAS.getQueryData(responseInitialData);
        setAddOns(mergeAddons(addOns, add_ons));

        return responseInitialData;
      }
    },
    [addOns, refetchInitialData, rvId]
  );

  useEffect(() => {
    async function refetchData() {
      setIsRefetchBookingModalDataLoading(true);

      await onRefetchRefetchInitialData();
      await onRefetchAdditionalInsuranceList();

      setIsRefetchBookingModalDataLoading(false);
    }

    if (isRefetchBookingModalData && !isRefetchBookingModalDataLoading) {
      setIsRefetchBookingModalData(false);
      refetchData();
    }
  }, [isRefetchBookingModalData, isRefetchBookingModalDataLoading, onRefetchAdditionalInsuranceList, onRefetchRefetchInitialData, setIsRefetchBookingModalData]);

  const handleCalculatePriceRefetch = useCallback(async (isCondition) => {
    if (
      isCondition
      && typeof calculatePriceRefetch === 'function'
      && isRefetchCalculatePrice
    ) {

      setIsLoadingCalculatePriceRefetch(true);
      await calculatePriceRefetch(pricesVariables);
      setIsLoadingCalculatePriceRefetch(false);
      setIsRefetchCalculatePrice(false);
    }
  }, [calculatePriceRefetch, isRefetchCalculatePrice, pricesVariables, setIsRefetchCalculatePrice]);

  useEffect(() => {
    setIsRefetchCalculatePrice(true);
  }, [addOns, selectedPackagesAdditionalInsurance, setIsRefetchCalculatePrice]);

  useEffect(() => {
    if (steps[currentStepIndex]?.id === STEPS.PAYMENT_OPTIONS.ID) {
      async function refetchData() {
        setIsLoadingCalculatePriceRefetch(true);

        await calculatePriceRefetch(pricesVariables);

        setIsLoadingCalculatePriceRefetch(false);
      }

      refetchData();
    }
  }, [calculatePriceRefetch, currentStepIndex, pricesVariables, steps]);


  useEffect(() => {
    if (prices?.trip_interruption_insurance) {
      setIsTII(Boolean(prices?.trip_interruption_insurance?.cents_total));
    }
  }, [prices]);

  const handleNextStep = useCallback(() => {
    trackSegmentNextStep(steps[currentStepIndex].stepNumber, steps[currentStepIndex + 1].stepNumber);
    setShowAsideRvInfoDetails(false);
    onNextStep();

    if (steps[currentStepIndex]?.id === STEPS.ADDONS.ID && isRefetchCalculatePrice) {
      onRefetchAdditionalInsuranceList();
    } else if (steps[currentStepIndex]?.id === STEPS.PAYMENT_METHOD.ID) {
      handleCalculatePriceRefetch(true);
    }
  }, [
    currentStepIndex,
    handleCalculatePriceRefetch,
    isRefetchCalculatePrice,
    onNextStep,
    onRefetchAdditionalInsuranceList,
    steps,
    trackSegmentNextStep
  ]);

  const handleOnClickStep = useCallback(
    (data) => {
      trackSegmentStep(data.selectedStep.stepNumber);

      if (steps[currentStepIndex]?.id === STEPS.ADDITIONAL_INSURANCE.ID) {
        if (isNextStep(selectedPackagesAdditionalInsurance, addInsuranceList)) {
          handleCalculatePriceRefetch(data?.isLastStep);
          onClickStep(data);
        } else {
          setError("Error Insurance");
        }
      } else {
        handleCalculatePriceRefetch(data?.isLastStep);
        onClickStep(data);
      }
    },
    [addInsuranceList, currentStepIndex, handleCalculatePriceRefetch, onClickStep, selectedPackagesAdditionalInsurance, steps, trackSegmentStep]
  );

  /*
   * Reserve action
   */
  const [reserveAction, reserveResult] = useMutation(reserveQuery, {
    onCompleted: async (data) => {
      if (reserveEAS.isSuccess({ data })) {
        onReserve(data.reserve?.rental?.id);
        trackSegmentConfirmAndPay();

        const isTII = data?.reserve?.rental?.is_trip_interruption_insurance_changable;

        pushGoogleAnalytics("bookingCompleted", {
          vehicleID: rvId,
          year: rvData?.year,
          manufacturer: rvData?.manufacturer,
          make: rvData?.make,
          model: rvData?.model,
          class: rvData?.class,
          departureDate: session.departureDate,
          returnDate: session.returnDate,
          pickUpLocation: session.location,
          deliveryLocation: session.deliveryLocation,
          totalFee: prices?.payment_total?.user_friendly,
          rentalRate: prices?.rental_fee?.user_friendly
        });
        pushSegmentAnalyticsIdentify(sso, identifyData);
      }

      if (reserveEAS.isErrors({ data })) {
        const errorCode = reserveEAS.errorCode({ data });
        const errorMessage = reserveEAS.errorMessage({ data });

        switch (errorCode) {
        case RESERVE_ERRORS.AVAILABILITY_ADD_ONS:
          onClickStepById(STEPS.ADDONS.ID);
          setError(errorMessage);
          setIsRefetchBookingModalDataLoading(true);

          await onRefetchRefetchInitialData();

          setIsRefetchBookingModalDataLoading(false);
          break;
        case RESERVE_ERRORS.AVAILABILITY_ADDITIONAL_INSURANCE:
          onClickStepById(STEPS.ADDITIONAL_INSURANCE.ID);
          setError(errorMessage);
          setIsRefetchBookingModalDataLoading(true);

          await onRefetchAdditionalInsuranceList();

          setIsRefetchBookingModalDataLoading(false);
          break;
        case RESERVE_ERRORS.AVAILABILITY_DELIVERY:
          onRefetchRvInitialData();
          onChangePickUp();
          setError(errorMessage);

          break;
        case RESERVE_ERRORS.BOOKING_RENTAL_FEE_LIMIT:
          setError(errorMessage);
          break;
        case RESERVE_ERRORS.BOOKING_TII_NOT_APPLICABLE:
          setError(errorMessage);
          break;
        default:
          break;
        }
      }
    }
  });
  const reserveLoading = reserveResult.loading;
  const errors = extractReserveErrors(reserveResult);
  const [reserveError] = useRequestErrors(errors);
  const reserve = useCallback(
    (message) => {
      trackSegmentPaymentOptionsCompleted();
      void reserveAction({
        variables: {
          rv: rvId,
          departure_date: session.departureDate,
          return_date: session.returnDate,
          delivery_location: session.deliveryLocation,
          specific_delivery_location_id: session.specific_delivery_location_id,
          rental_type: session.rentalType,
          coupon_code: coupon?.code,
          insurance_package: insuranceId,
          good_sam_membership_package: membershipId,
          no_split_payments: isNoSplitPayments,
          add_ons: addOnsIds,
          message,
          signature_name: tiiDefaultTags[ADD_INSURANCE_DEFAULT_TAGS.NAME],
          date_of_birth: tiiDefaultTags[ADD_INSURANCE_DEFAULT_TAGS.BIRTH],
          additional_insurances: removeExtraPackagesId(getTotalPackagesIds(selectedPackagesAdditionalInsurance, addInsuranceList || {})),
          tags: []
        }
      });
    },
    [reserveAction, trackSegmentPaymentOptionsCompleted, rvId, session.departureDate, session.returnDate, session.deliveryLocation, session.specific_delivery_location_id, session.rentalType, coupon?.code, insuranceId, membershipId, isNoSplitPayments, addOnsIds, tiiDefaultTags, selectedPackagesAdditionalInsurance, addInsuranceList]
  );

  /*
   * Result Value
   */

  const isLoading = useMemo(() => (membershipOffersLoading || linksContentQueryLoading || isRefetchBookingModalDataLoading || additionalInsuranceListLoading || pricesLoading || isLoadingCalculatePriceRefetch || loading || interruptionInsuranceDataLoading), [
    linksContentQueryLoading,
    isRefetchBookingModalDataLoading,
    additionalInsuranceListLoading,
    pricesLoading,
    isLoadingCalculatePriceRefetch,
    loading,
    interruptionInsuranceDataLoading,
    membershipOffersLoading
  ]);

  useEffect(() => {
    if (!firstSegmentLoaded.current && rvData && additionalInsuranceListData?.additional_insurance_list?.insurances && !isLoading) {
      if (rvData?.add_ons?.length > 0) {
        trackSegmentAddonsLoad();
      } else if (additionalInsuranceListData?.additional_insurance_list?.insurances?.length > 0) {
        trackSegmentAdditionalInsuranceLoad();
      } else {
        trackSegmentPaymentMethodLoad();
      }
      firstSegmentLoaded.current = true;
    }
  }, [rvData, additionalInsuranceListData, trackSegmentAddonsLoad, trackSegmentAdditionalInsuranceLoad, trackSegmentPaymentMethodLoad, isLoading]);

  const value = useMemo(
    () => ({
      pricesErrors,
      errors,
      steps,
      loading,
      rvData,
      addInsuranceList,
      selectedPackagesAdditionalInsurance,
      setSelectedPackagesAdditionalInsurance,
      user,
      session,
      rentalType,
      rentalTypeValue,
      currentStep,
      stepIndex,
      setStepIndex: _setStepIndex,
      currentStepIndex,
      onClickStep,
      onNextStep,
      onPrevStep,
      handleOnClickStep,
      addOns,
      addOnsIds,
      setAddOns,
      completedSteps,
      setStepCompleted,
      handleNextStep,
      pricesLoading,
      prices,
      price_form_detailed,
      pricesError,
      coupon,
      setCoupon,
      membershipId,
      setMembershipId,
      isInterruptionVisible,
      setIsInterruptionVisible,
      setIsNoSplitPayments,
      isIMGlobalIntegrationIsEnabled,
      interruptionInsuranceContent,
      insuranceId,
      setInsuranceId,
      reserve,
      reserveError,
      reserveLoading,
      toggleShowPickUpDelivery: onChangePickUp,
      toggleShowCalendar: onChangeCalendar,
      isModalHidden,
      setIsModalHidden,
      showAsideRvInfoDetails,
      setShowAsideRvInfoDetails,
      isInstantRental,
      rvLocationData,
      isMembershipValidation,
      setIsMembershipValidation,
      setIsRefetchCalculatePrice,
      isLoadingCalculatePriceRefetch,
      additionalInsuranceListLoading,
      isRefetchBookingModalDataLoading,
      setError,
      onClickStepById,
      tiiDefaultTags,
      setTiiDefaultTags,
      isLoading,
      isClearTii,
      setIsClearTii
    }),
    [pricesErrors,
      errors,
      steps,
      loading,
      rvData,
      addInsuranceList,
      selectedPackagesAdditionalInsurance,
      setSelectedPackagesAdditionalInsurance,
      user,
      session,
      rentalType,
      rentalTypeValue,
      currentStep,
      stepIndex,
      _setStepIndex,
      currentStepIndex,
      onClickStep,
      onNextStep,
      onPrevStep,
      handleOnClickStep,
      addOns,
      addOnsIds,
      setAddOns,
      completedSteps,
      setStepCompleted,
      handleNextStep,
      pricesLoading,
      prices,
      price_form_detailed,
      pricesError,
      coupon,
      setCoupon,
      membershipId,
      setMembershipId,
      isInterruptionVisible,
      setIsInterruptionVisible,
      setIsNoSplitPayments,
      isIMGlobalIntegrationIsEnabled,
      interruptionInsuranceContent,
      insuranceId,
      setInsuranceId,
      reserve,
      reserveLoading,
      reserveError,
      onChangePickUp,
      onChangeCalendar,
      isModalHidden,
      setIsModalHidden,
      showAsideRvInfoDetails,
      setShowAsideRvInfoDetails,
      isInstantRental,
      rvLocationData,
      isMembershipValidation,
      setIsMembershipValidation,
      setIsRefetchCalculatePrice,
      isLoadingCalculatePriceRefetch,
      additionalInsuranceListLoading,
      isRefetchBookingModalDataLoading,
      setError,
      onClickStepById,
      tiiDefaultTags,
      setTiiDefaultTags,
      isLoading,
      isClearTii,
      setIsClearTii
    ]
  );

  return (
    <>
      <BookingModalContext.Provider value={value} {...props} />

      {error?.length > 0 && (
        <NotificationPopover
          show
          status="error"
          text={error}
          onClose={() => {
            setError('');
          }}
          bottomIndent={{
            hasIndent: true,
            size: 'small'
          }}
        />
      )}
    </>
  );
};

BookingModalProvider.propTypes = {
  rvId: PropTypes.number.isRequired,
  session: BookingSession.isRequired,
  onReserve: PropTypes.func.isRequired,
  onChangePickUp: PropTypes.func,
  onChangeCalendar: PropTypes.func
};

export default BookingModalProvider;
