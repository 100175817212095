import { gql } from "@apollo/client";

export const userSegmentContextQuery = gql`  
  query {
    user {
      id
      first_name
      last_name
      email
      phone
      city
      state
      zip
      payment_info {
        address
        city
        state
        zip
      }
    }
  }
`;
