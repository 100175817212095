import { gql } from "@apollo/client";
import { fragments } from "../queries/rvQuery";

export const locationDeliveryMutation = gql`
  ${fragments.generalInfo},
  ${fragments.rvDetails},
  ${fragments.listingDetails},
  ${fragments.images},
  ${fragments.amenities},
  ${fragments.location},
  ${fragments.rentalFees},
  ${fragments.otherFeesAndAddons},
  ${fragments.publishedListingValues},
  ${fragments.availability},
  ${fragments.availabilityCalender}

  mutation rvUpdate(
    $id: Int!
    $location: String!
    $allow_standard_rental: Boolean
    $allow_delivery_only_rental: Boolean
    $delivery_option: Boolean
    $free_delivery: Int
    $paid_delivery: Int
    $delivery_price_type: Int
    $cost_per_mile: Float
    $minimum_fee: Float
    $flat_delivery_price: Float
    $specific_delivery_locations: [SpecificDeliveryLocationInput]
    $specific_delivery_locations_to_delete: [Int]
    $dor_delivery_option: Boolean
    $dor_free_delivery: Int
    $dor_paid_delivery: Int
    $dor_delivery_price_type: Int
    $dor_cost_per_mile: Float
    $dor_minimum_fee: Float
    $dor_flat_delivery_price: Float
    $dor_specific_delivery_locations: [SpecificDeliveryLocationInput]
    $dor_specific_delivery_locations_to_delete: [Int]
    $specific_delivery_locations_allowed: Boolean
    $dor_specific_delivery_locations_allowed: Boolean
  ) {
    rv_update(
      step: "location"
      id: $id
      location: $location
      allow_standard_rental: $allow_standard_rental
      allow_delivery_only_rental: $allow_delivery_only_rental
      delivery_option: $delivery_option
      free_delivery: $free_delivery
      paid_delivery: $paid_delivery
      delivery_price_type: $delivery_price_type
      cost_per_mile: $cost_per_mile
      minimum_fee: $minimum_fee
      flat_delivery_price: $flat_delivery_price
      specific_delivery_locations: $specific_delivery_locations
      specific_delivery_locations_to_delete: $specific_delivery_locations_to_delete
      dor_delivery_option: $dor_delivery_option
      dor_free_delivery: $dor_free_delivery
      dor_paid_delivery: $dor_paid_delivery
      dor_delivery_price_type: $dor_delivery_price_type
      dor_cost_per_mile: $dor_cost_per_mile
      dor_minimum_fee: $dor_minimum_fee
      dor_flat_delivery_price: $dor_flat_delivery_price
      dor_specific_delivery_locations: $dor_specific_delivery_locations
      dor_specific_delivery_locations_to_delete: $dor_specific_delivery_locations_to_delete
      specific_delivery_locations_allowed: $specific_delivery_locations_allowed
      dor_specific_delivery_locations_allowed: $dor_specific_delivery_locations_allowed
    ) {
      result {
        success
        errors {
          code
          field
          message
          section
        }
      }
      rv {
        id
        fuel_type {
          key
          value
        }
        listing_steps_completed
        current_miles
        minimum_daily_rate {
          cents_total
        }
        amenities {
          id
          name
          icon
        }
        fields_awaiting_moderation
        owner {
          id
          good_sam_membership_validation {
            key
            value
          }
        }
        class
        make
        model
        year
        length {
          inches_total
          feet_as_part
        }
        price {
          user_friendly
        }
        sleeps
        slide_outs
        weight
        stated_value {
          cents_total
        }
        daily_rate {
          dollars_as_part
          cents_total
        }
        specific_delivery_locations_allowed
        dor_specific_delivery_locations_allowed
        status {
          key
          value
        }
        ...GeneralInfo,
        ...RvDetails,
        ...ListingDetails,
        ...Images,
        ...Amenities,
        ...Location,
        ...RentalFees,
        ...OtherFeesAndAddons
        ...PublishedListingValues
        ...Availability,
        ...AvailabilityCalender
      }
    }
  }
`;
