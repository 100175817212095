import React, { useState, useCallback, useEffect, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  lensProp,
  over,
  path,
  pipe,
  prop,
  defaultTo,
  filter,
  map,
  pick
} from "ramda";
import { useMutation } from "@apollo/client";
import { RvDetailsForm } from "./RvDetailsForm";
import { ListingContext } from "../../ListingContext";
import { rvDetailsMutation } from "../../mutations/rvDetailsMutation";
import { filterInitialData } from "../filterInitialData";
import {
  extractServerErrors
} from "../../../../utils/extractErrors";
import { SpinnerBack } from "../../../Spinner/Spinner";
import { ContentLoader } from "components/ContentSection";
import { rvDetailsSectionContentQuery } from "components/Listing/queries/listingSectionContentQuery";
import { pushGoogleAnalytics } from "utils/google-analytics/push";
import { handleListingSegment } from "components/Listing/utils/handleListingSegment";
import { getErrorsAndSuccess } from "utils/extractErrors";
import { VIN_ERRORS } from "constants/errorCodes";
import { ModalChoice } from "components/ModalChoice/ModalChoice";
import { Button as ButtonContent, Text } from "components/ContentSection/elements";
import { useListingSegment } from "components/Listing/hooks/useListingSegment";

const extractRvDetailsErrors = extractServerErrors("rv_update");
const extractRv = path(["data", "rv_update", "rv"]);
const rvDetailsMutationEAS = getErrorsAndSuccess("rv_update");

const filterInitialListingDetails = filterInitialData([
  "length",
  "weight",
  "slide_outs",
  "sleeps",
  "fuel_type",
  "seatbelts",
  "electric_service",
  "gray_water",
  "gray_water_tank",
  "black_water",
  "black_water_tank",
  "fresh_water",
  "fresh_water_tank",
  "propane",
  "propane_tank",
  "vin",
  "stated_value",
  "current_miles"
]);

const defaultToZero = defaultTo(0);

const processInitialData = pipe(
  over(lensProp("length"), pipe(path(["user_friendly", 0]), defaultToZero)),
  over(
    lensProp("stated_value"),
    pipe(prop("cents_total"), defaultToZero, (v) => Math.round(v / 100))
  ),
  over(lensProp("fuel_type"), pipe(prop("key"), defaultTo(""))),
  filterInitialListingDetails
);

const normalizeValues = pipe(
  over(lensProp("weight"), (v) => parseInt(v, 10)),
  over(lensProp("slide_outs"), (v) => parseInt(v, 10)),
  over(lensProp("sleeps"), (v) => parseInt(v, 10)),
  over(lensProp("seatbelts"), (v) => parseInt(v, 10)),
  over(lensProp("electric_service"), (v) => parseInt(v, 10)),
  over(lensProp("gray_water_tank"), (v) => parseInt(v, 10)),
  over(lensProp("black_water_tank"), (v) => parseInt(v, 10)),
  over(lensProp("fresh_water_tank"), (v) => parseInt(v, 10)),
  over(lensProp("propane_tank"), (v) => parseInt(v, 10)),
  over(lensProp("stated_value"), (v) => parseInt(v, 10)),
  over(lensProp("current_miles"), (v) => parseInt(v, 10))
);

const filterPublishErrorsBySection = pipe(
  defaultTo([]),
  filter((v) => v.section === "rv_details"),
  map(pick(["field", "message"]))
);
const cleanSectionErrors = pipe(
  defaultTo([]),
  filter((v) => v.section !== "rv_details")
);

const RvDetailsDataProvider = ({
  context,
  updateContext,
  currStep,
  prevStep,
  nextStep
}) => {
  const isSegmentSent = useRef(null);
  const { id: initialId, not_applicable_listing_options, rv_name } = context.initialData;
  const history = useHistory();
  const [action, result] = useMutation(rvDetailsMutation);
  const [vinModalData, setVinModalData] = useState(null);
  const { trackSegmentRVDetailsLoad, trackSegmentRVDetailsCompleted } = useListingSegment(context);

  const handleSave = useCallback(
    async (values, { toNextStep = true } = {}, force = false) => {
      const response = await action({
        variables: {
          ...normalizeValues(values),
          id: initialId,
          force
        }
      });
      if (rvDetailsMutationEAS.isSuccess(response)) {
        const rv = extractRv(response);

        if (rv) {
          trackSegmentRVDetailsCompleted(context?.initialData, rv, context?.userId);
          pushGoogleAnalytics("listVehicleRVDetails", {
            length: rv?.length?.user_friendly,
            weight: rv?.weight,
            fuelType: rv?.fuel_type?.key
          });

          updateContext({
            initialData: {
              ...context.initialData,
              ...rv
            },
            errors: cleanSectionErrors(context.errors)
          });

          if (toNextStep && nextStep) {
            history.push(`/listing/${nextStep.path}?id=${rv.id}`, {
              prevStep: currStep
            });
          }
        }
      } else if(rvDetailsMutationEAS.isErrors(response)) {
        if (rvDetailsMutationEAS.errorCode(response) === VIN_ERRORS.VIN_ALREADY_EXISTS) {
          setVinModalData({
            show: true,
            variables: {
              values,
              toNextStep: { toNextStep },
              force: true
            }
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [action, initialId, updateContext, context.initialData, context.errors, nextStep, history, currStep, context?.userId]
  );

  const vinModalClose = useCallback(() => {
    setVinModalData(null);
  }, []);

  const vinModalAction = useCallback(() => {
    vinModalClose();
    handleSave(...Object.values(vinModalData?.variables));
  }, [handleSave, vinModalClose, vinModalData?.variables]);

  const handleBack = useCallback(() => {
    // console.log(`/listing/${prevStep.path}?id=${initialId}`);
    if (prevStep) {
      history.push(`/listing/${prevStep.path}?id=${initialId}`);
    }
  }, [history, initialId, prevStep]);

  const handleLater = useCallback(() => {
    if (nextStep) {
      history.push(`/listing/${nextStep.path}?id=${initialId}`);
    }
  }, [history, initialId, nextStep]);

  const updateContextSaveHandler = useCallback(
    (cb) => {
      updateContext({
        handleSave: cb
      });
    },
    [updateContext]
  );

  useEffect(() => {
    if (!isSegmentSent.current && context?.userId) {
      trackSegmentRVDetailsLoad(context?.userId);
      isSegmentSent.current = true;
    }
  }, [trackSegmentRVDetailsLoad, context?.userId]);

  const publishErrors = filterPublishErrorsBySection(context.errors);
  const stepErrors = extractRvDetailsErrors(result)?.filter((item) => item?.code !== VIN_ERRORS.VIN_ALREADY_EXISTS);

  return (
    <>
      <RvDetailsForm
        initialData={processInitialData(context.initialData)}
        notApplicableOptions={not_applicable_listing_options}
        requestErrors={publishErrors.length ? publishErrors : stepErrors}
        onBack={handleBack}
        onLater={handleLater}
        onSave={handleSave}
        loading={result.loading}
        updateContextSaveHandler={updateContextSaveHandler}
        listingPublished={context.isListingPublished}
        prevStep={prevStep}
        nextStep={nextStep}
      />

      {vinModalData?.show && (
        <ModalChoice
          show={true}
          title={(
            <Text
              id="modal_vin_duplicate_title"
              section="rvDetailsSectionContent"
            />
          )}
          description={(
            <Text
              id="modal_vin_duplicate_text"
              section="rvDetailsSectionContent"
              variables={{
                'vehicle_name': rv_name
              }}
            />
          )}
          cancelButtonContent={() => (
            <ButtonContent
              id="modal_vin_duplicate_button_cancel"
              section="rvDetailsSectionContent"
              className="mr-20"
              onClick={vinModalClose}
            />
          )}
          actionButtonContent={() => (
            <ButtonContent
              id="modal_vin_duplicate_button_save"
              section="rvDetailsSectionContent"
              onClick={vinModalAction}
            />
          )}
          onClose={vinModalClose}
        />
      )}

      {result.loading && <SpinnerBack />}

    </>
  );
};

function everyKey(keys) {
  return (data) => keys.every((k) => data[k]);
}

const rvDetailsSectionContentPredicate = everyKey(["rvDetailsSectionContent"]);
export const RvDetails = ({ currStep, prevStep, nextStep }) => {
  return (
    <ListingContext.Consumer>
      {(context) => (
        <ContentLoader
          query={rvDetailsSectionContentQuery}
          predicate={rvDetailsSectionContentPredicate}
          render={() => (
            <RvDetailsDataProvider
              context={context[0]}
              updateContext={context[1]}
              currStep={currStep}
              prevStep={prevStep}
              nextStep={nextStep}
            />
          )}
        />
      )}
    </ListingContext.Consumer>
  );
};
