import { useEffect } from "react";
import { useQuery } from '@apollo/client';
import { userSegmentContextQuery } from "queries/userSegmentContextQuery";
import { pushSegmentAnalyticsAddContext } from "utils/segment-analytics/push";

export function useAddSegmentContext() {
  const { data } = useQuery(userSegmentContextQuery, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (data?.user?.id) {
      const user = data?.user;
      pushSegmentAnalyticsAddContext({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        address: {
          street: user?.payment_info?.address,
          city: user?.city || user?.payment_info?.city,
          state: user?.state || user?.payment_info?.state,
          postal_code: user?.zip || user?.payment_info?.zip
        }
      });
    }
  }, [data]);

  return {
    segmentDataUserId: data?.user?.id
  };
}
