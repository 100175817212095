import { gql } from "@apollo/client";

export const fragments = {
  generalInfo: gql`
    fragment GeneralInfo on RV {
      year
      manufacturer
      make
      model
      class
    }
  `,
  rvDetails: gql`
    fragment RvDetails on RV {
      length {
        feet_as_part
        user_friendly
        inches_total
      }
      weight
      slide_outs
      sleeps
      fuel_type {
        key
        value
      }
      current_miles
      minimum_daily_rate {
        cents_total
      }
      rv_name
      seatbelts
      electric_service
      gray_water
      gray_water_tank
      black_water
      black_water_tank
      fresh_water
      fresh_water_tank
      propane
      propane_tank
      vin
      miles_included
      stated_value {
        cents_total
      }
      current_miles
    }
  `,
  listingDetails: gql`
    fragment ListingDetails on RV {
      listing_title
      listing_description
      what_is_included
      recommendations
    }
  `,
  images: gql`
    fragment Images on RV {
      images {
        id
        srcset {
          type
          url
        }
        caption
      }
    }
  `,
  amenities: gql`
    fragment Amenities on RV {
      amenities {
        id
        name
        icon
      }
    }
  `,
  location: gql`
    fragment Location on RV {
      location {
        full_address
        building
        street
        city
        state
        zip
        zip_suffix
        latitude
        longitude
        short_address
      }
      delivery_option
      allow_standard_rental
      allow_delivery_only_rental
      delivery_price_type
      free_delivery
      paid_delivery
      cost_per_mile {
        cents_total
      }
      minimum_fee {
        cents_total
      }
      flat_delivery_price {
        cents_total
      }
      dor_delivery_option
      dor_free_delivery
      dor_paid_delivery
      dor_delivery_price_type
      dor_cost_per_mile {
        cents_total
      }
      dor_minimum_fee {
        cents_total
      }
      dor_flat_delivery_price {
        cents_total
      }
      specific_delivery_locations {
        id
        delivery_fee {
          cents_total
          dollars_as_part
          user_friendly
        }
        description
        location {
            full_address
            latitude
            longitude
        }
      }
      dor_specific_delivery_locations {
        id
        delivery_fee {
          cents_total
          dollars_as_part
          user_friendly
        }
        description
        location {
            full_address
            building
            street
            city
            state
            zip
            zip_suffix
            latitude
            longitude
        }
      }
    }
  `,
  rentalFees: gql`
    fragment RentalFees on RV {
      minimum_duration
      daily_rate {
        cents_total
        dollars_as_part
        user_friendly
      }
      minimum_daily_rate {
        cents_total
      }
      suggested_daily_rate {
        user_friendly
      }
      discounts {
        from
        till
        discount
      }
      security_deposit {
        cents_total
        user_friendly
      }
      instant_rentals
      instant_rentals__days_before_the_rental
    }
  `,
  otherFeesAndAddons: gql`
    fragment OtherFeesAndAddons on RV {
      limited_daily_mileage
      miles_included
      mileage_overage_rate {
        cents_total
      }
      limited_generator_hours
      generator_not_applicable
      generator_hours_included
      generator_overage_rate {
        cents_total
      }
      cleaning_fee {
        cents_total
      }
      disposal_fee {
        cents_total
      }
      add_ons {
        name
        price {
          cents_total
        }
        price_type {
          key
          value
        }
      }
    }
  `,
  publishedListingValues: gql`
    fragment PublishedListingValues on RV {
      public_url
      number_of_requests
      number_of_confirmed_trips
      number_of_completed_trips
      number_of_expired_trips
      views
      rating
      number_of_reviews
      total_revenue {
        cents_total
      }
    }
  `,
  availability: gql`
    fragment Availability on RV {
      visible
    }
  `,
  availabilityCalender: gql`
    fragment AvailabilityCalender on RV {
      availability_calendar {
        date
        busy
        available
        price {
          dollars_as_part
          cents_total
          user_friendly
          dollars_as_part
        }
      }
    }
  `
};

export const rvQuery = gql`
  ${fragments.generalInfo},
  ${fragments.rvDetails},
  ${fragments.listingDetails},
  ${fragments.images},
  ${fragments.amenities},
  ${fragments.location},
  ${fragments.rentalFees},
  ${fragments.otherFeesAndAddons},
  ${fragments.publishedListingValues},
  ${fragments.availability},
  ${fragments.availabilityCalender}

  query ($id: Int!) {
    rv(id: $id, for_listing: true) {
      id,
      status {
        key,
        value
      },
      owner {
        id
        good_sam_membership_validation {
          key
          value
        }
      }
      listing_steps_completed,
      not_applicable_listing_options,
      fields_awaiting_moderation,
      price {
        user_friendly
      }
      amenities {
        id
        name
        icon
      }
      is_new_photos_from_garage,
      rv_garage_id,
      specific_delivery_locations_allowed,
      dor_specific_delivery_locations_allowed,
      ...GeneralInfo,
      ...RvDetails,
      ...ListingDetails,
      ...Images,
      ...Amenities,
      ...Location,
      ...RentalFees,
      ...OtherFeesAndAddons
      ...PublishedListingValues
      ...Availability,
      ...AvailabilityCalender
    }
  }
`;
