import { convertCents } from "utils/convertCents";

export const extractGSM = ({ gsmOffers = [], membershipId = null }) => {
  if (!membershipId) return [];

  const offer = gsmOffers.find((ofr) => ofr?.id === membershipId);

  if (offer) {
    return [{
      brand: 'GS',
      category: '3651',
      name: 'ENROLL',
      price: Number(convertCents(offer.price_total.cents_total)),
      tax: Number(convertCents(offer.tax.cents_total)),
      revenue: Number(convertCents(offer.price_total.cents_total)),
      product_id: offer.description.replace(/<\/?[^>]+(>|$)/g, "").trim(),
      quantity: offer.quantity,
      sku: offer.description.replace(/<\/?[^>]+(>|$)/g, "").trim(),
      variant: ''
    }];
  }

  return [];
};
