import { convertCents } from "utils/convertCents";

export const extractInsurances = ({ data, selected }) => {
  if (!data?.insurances) return [];

  const insurances = data.insurances
    .filter(insurance => selected.insurancesIds.includes(insurance.id))
    .map(insurance => {
      const selectedPackage = insurance.packages.find(pkg => selected.packagesIds.includes(pkg.id));

      return selectedPackage ? {
        insurance_name: insurance.name,
        insurance_pack_name: selectedPackage.name,
        insurance_cost: selectedPackage.price
      } : null;
    })
    .filter(item => item !== null);

  const total_coverage = insurances.reduce((sum, item) => sum + item.insurance_cost, 0);
  const formatted_total_coverage = Number(convertCents(total_coverage));

  return { insurances, total_coverage: formatted_total_coverage };
};

export const extractAddOns = ({ addOns, addOnsIds }) => {
  return addOns
    .filter(addon => addOnsIds.includes(addon.id))
    .map(addon => addon.name);
};

export const extractGSM = ({ gsmOffers = [], membershipId = null }) => {
  if (!membershipId) return [];

  const offer = gsmOffers.find((ofr) => ofr?.id === membershipId);

  if (offer) {
    return [{
      brand: 'GS',
      category: '3651',
      name: 'ENROLL',
      price: Number(convertCents(offer.price_total.cents_total)),
      tax: Number(convertCents(offer.tax.cents_total)),
      revenue: Number(convertCents(offer.price_total.cents_total)),
      product_id: offer.description.replace(/<\/?[^>]+(>|$)/g, "").trim(),
      quantity: offer.quantity,
      sku: offer.description.replace(/<\/?[^>]+(>|$)/g, "").trim(),
      variant: ''
    }];
  }

  return [];
};
