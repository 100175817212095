import { gql } from "@apollo/client";
import { fragments } from "../queries/rvQuery";

export const generalInfoMutation = gql`
  ${fragments.generalInfo},
  ${fragments.rvDetails},
  ${fragments.listingDetails},
  ${fragments.images},
  ${fragments.amenities},
  ${fragments.location},
  ${fragments.rentalFees},
  ${fragments.otherFeesAndAddons},
  ${fragments.publishedListingValues},
  ${fragments.availability},
  ${fragments.availabilityCalender}

  mutation rvUpdate($id: Int, $rv_garage_id: String, $year: Int!, $manufacturer: String!, $make: String!, $model: String!, $class: String!) {
    rv_update(step: "general_info", id: $id, rv_garage_id: $rv_garage_id, year: $year, manufacturer: $manufacturer, make: $make, model: $model, class: $class) {
      result {
        success
        errors {
          field
          message
        }
      },
      rv {
        id,
        fuel_type {
          key
          value
        }
        listing_steps_completed
        current_miles
        minimum_daily_rate {
          cents_total
        }
        amenities {
          id
          name
          icon
        }
        status {
          key,
          value
        },
        owner {
          id
          good_sam_membership_validation {
            key
            value
          }
        }
        listing_steps_completed,
        not_applicable_listing_options,
        fields_awaiting_moderation
        ...GeneralInfo,
        ...RvDetails,
        ...ListingDetails,
        ...Images,
        ...Amenities,
        ...Location,
        ...RentalFees,
        ...OtherFeesAndAddons
        ...PublishedListingValues
        ...Availability,
        ...AvailabilityCalender
      }
    }
  }
`;
