import { gql } from "@apollo/client";
import { fragments } from "../queries/rvQuery";

export const feesMutation = gql`
  ${fragments.generalInfo},
  ${fragments.rvDetails},
  ${fragments.listingDetails},
  ${fragments.images},
  ${fragments.amenities},
  ${fragments.location},
  ${fragments.rentalFees},
  ${fragments.otherFeesAndAddons},
  ${fragments.publishedListingValues},
  ${fragments.availability},
  ${fragments.availabilityCalender}

  mutation rvUpdate(
    $id: Int!
    $minimum_duration: Int!
    $daily_rate: Int!
    $discounts: [DiscountInput]!
    $security_deposit: Int!
    $instant_rentals: Boolean
    $minimum_days_before_instant_rental: Int
  ) {
    rv_update(
      step: "fees"
      id: $id
      minimum_duration: $minimum_duration
      daily_rate: $daily_rate
      discounts: $discounts
      security_deposit: $security_deposit
      instant_rentals: $instant_rentals
      minimum_days_before_instant_rental: $minimum_days_before_instant_rental
    ) {
      result {
        success
        errors {
          field
          message
        }
      }
      rv {
        id
        fuel_type {
          key
          value
        }
        listing_steps_completed
        current_miles
        minimum_daily_rate {
          cents_total
        }
        amenities {
          id
          name
          icon
        }
        fields_awaiting_moderation
        owner {
          id
          good_sam_membership_validation {
            key
            value
          }
        }
        class
        make
        model
        year
        length {
          feet_as_part
          inches_total
        }
        price {
          user_friendly
        }
        sleeps
        slide_outs
        weight
        stated_value {
          cents_total
        }
        daily_rate {
          dollars_as_part
        }
        status {
          key
          value
        }
        ...GeneralInfo,
        ...RvDetails,
        ...ListingDetails,
        ...Images,
        ...Amenities,
        ...Location,
        ...RentalFees,
        ...OtherFeesAndAddons
        ...PublishedListingValues
        ...Availability,
        ...AvailabilityCalender
      }
    }
  }
`;
