import { useCallback } from "react";
import { pushSegmentAnalyticsTrack } from "utils/segment-analytics/push";
import { getFbp, getFbc } from "utils/segment-analytics/utils/facebookPixel";
import { getData, processListingData } from "../utils/listingSegmentUtils";

export const useListingSegment = (context) => {
  const { initialData = {} } = context;
  const listing_status = initialData?.id ? initialData?.status?.value : 'Incomplete';
  const listing_steps_completed = initialData?.listing_steps_completed;

  const trackSegmentGeneralInfoLoad = useCallback((owner_id) => {
    const data = initialData?.id ? getData(context?.initialData, listing_steps_completed, context) : {};

    pushSegmentAnalyticsTrack('Listing Started', {
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      goodSamMember: context?.isGoodSamMember,
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...data
    });
  }, [initialData?.id, context, listing_status, listing_steps_completed]);

  const trackSegmentGeneralInfoListingStepViewed = useCallback((owner_id) => {
    const data = initialData?.id ? getData(context?.initialData, listing_steps_completed, context) : {};

    pushSegmentAnalyticsTrack('Listing Step Viewed', {
      step: 1,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'General info',
      goodSamMember: context?.isGoodSamMember,
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...data
    });
  }, [context, initialData?.id, listing_status, listing_steps_completed]);

  const trackSegmentGeneralInfoCompleted = useCallback((prevRv, nextRv, owner_id) => {
    const { resultData, eventType } = processListingData(prevRv, nextRv, listing_steps_completed, context);

    pushSegmentAnalyticsTrack(eventType, {
      step: 1,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'General info',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: prevRv?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...resultData
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentRVDetailsLoad = useCallback((owner_id) => {
    const data = getData(context?.initialData, listing_steps_completed, context);

    pushSegmentAnalyticsTrack('Listing Step Viewed', {
      step: 2,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Rv details',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: context?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...data
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentRVDetailsCompleted = useCallback((prevRv, nextRv, owner_id) => {
    const { resultData, eventType } = processListingData(prevRv, nextRv, listing_steps_completed, context);

    pushSegmentAnalyticsTrack(eventType, {
      step: 2,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Rv details',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: context?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...resultData
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentListingDetailsLoad = useCallback((owner_id) => {
    const data = getData(context?.initialData, listing_steps_completed, context);

    pushSegmentAnalyticsTrack('Listing Step Viewed', {
      step: 3,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Listing details',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: context?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...data
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentListingDetailsCompleted = useCallback((prevRv, nextRv, owner_id) => {
    const { resultData, eventType } = processListingData(prevRv, nextRv, listing_steps_completed, context);

    pushSegmentAnalyticsTrack(eventType, {
      step: 3,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Listing details',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: prevRv?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...resultData
    });
  }, [context, listing_status, listing_steps_completed]);


  const trackSegmentPhotosLoad = useCallback((owner_id) => {
    const data = getData(context?.initialData, listing_steps_completed, context);

    pushSegmentAnalyticsTrack('Listing Step Viewed', {
      step: 4,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Photos',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: context?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...data
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentPhotosCompleted = useCallback((prevRv, nextRv, owner_id) => {
    const { resultData, eventType } = processListingData(prevRv, nextRv, listing_steps_completed, context);

    pushSegmentAnalyticsTrack(eventType, {
      step: 4,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Photos',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: prevRv?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...resultData
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentAmenitiesLoad = useCallback((owner_id) => {
    const data = getData(context?.initialData, listing_steps_completed, context);

    pushSegmentAnalyticsTrack('Listing Step Viewed', {
      step: 5,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Amenities',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: context?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...data
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentAmenitiesCompleted = useCallback((prevRv, nextRv, owner_id) => {
    const { resultData, eventType } = processListingData(prevRv, nextRv, listing_steps_completed, context);

    pushSegmentAnalyticsTrack(eventType, {
      step: 5,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Amenities',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: prevRv?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...resultData
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentLocationLoad = useCallback((owner_id) => {
    const data = getData(context?.initialData, listing_steps_completed, context);

    pushSegmentAnalyticsTrack('Listing Step Viewed', {
      step: 6,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Location & delivery options',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: context?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...data
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentLocationCompleted = useCallback((prevRv, nextRv, owner_id) => {
    const { resultData, eventType } = processListingData(prevRv, nextRv, listing_steps_completed, context);

    pushSegmentAnalyticsTrack(eventType, {
      step: 6,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Location & delivery options',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: prevRv?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...resultData
    });
  }, [context, listing_status, listing_steps_completed]);


  const trackSegmentFeesLoad = useCallback((owner_id) => {
    const data = getData(context?.initialData, listing_steps_completed, context);

    pushSegmentAnalyticsTrack('Listing Step Viewed', {
      step: 7,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Fees',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: context?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...data
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentFeesCompleted = useCallback((prevRv, nextRv, owner_id) => {
    const { resultData, eventType } = processListingData(prevRv, nextRv, listing_steps_completed, context);

    pushSegmentAnalyticsTrack(eventType, {
      step: 7,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Fees',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: prevRv?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...resultData
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentOtherFeesLoad = useCallback((owner_id) => {
    const data = getData(context?.initialData, listing_steps_completed, context);

    pushSegmentAnalyticsTrack('Listing Step Viewed', {
      step: 8,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Other fees & add-ons',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: context?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...data
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentOtherFeesCompleted = useCallback((prevRv, nextRv, owner_id) => {
    const { resultData, eventType } = processListingData(prevRv, nextRv, listing_steps_completed, context);

    pushSegmentAnalyticsTrack(eventType, {
      step: 8,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Other fees & add-ons',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: prevRv?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...resultData
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentAdditionalInsuranceLoad = useCallback((owner_id) => {
    const data = getData(context?.initialData, listing_steps_completed, context);
    pushSegmentAnalyticsTrack('Listing Step Viewed', {
      step: 9,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Additional insurance',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: context?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...data
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentAdditionalInsuranceCompleted = useCallback((prevRv, nextRv, { insurances, rvAmenities }, owner_id) => {
    const listing_steps_completed = context?.initialData?.listing_steps_completed;
    const { resultData, eventType } = processListingData(prevRv, nextRv, listing_steps_completed, context, { insurances, rvAmenities });

    pushSegmentAnalyticsTrack(eventType, {
      step: 9,
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Additional insurance',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: nextRv?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...resultData
    });
  }, [context, listing_status]);

  const trackSegmentAvailabilityLoad = useCallback((owner_id) => {
    const data = getData(context?.initialData, listing_steps_completed, context);
    pushSegmentAnalyticsTrack('Listing Step Viewed', {
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Availability',
      listing_status: context?.initialData?.visible ? listing_status : 'Inactive',
      goodSamMember: context?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...data
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentAvailabilityCompleted = useCallback((owner_id, visible) => {
    const data = getData(context?.initialData, listing_steps_completed, context);

    pushSegmentAnalyticsTrack(context?.initialData?.visible === visible ? 'Listing Step Completed' : 'Listing Updated', {
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      step_name: 'Availability',
      goodSamMember: context?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...data,
      ...(context?.initialData?.visible !== visible && !Boolean(context?.initialData?.visible) && {
        listing_status_orig: 'Inactive',
        listing_status: listing_status
      }),
      ...(context?.initialData?.visible !== visible && Boolean(context?.initialData?.visible) && {
        listing_status_orig: listing_status,
        listing_status: 'Inactive'
      })
    });
  }, [context, listing_status, listing_steps_completed]);

  const trackSegmentSubmitListing = useCallback((context, owner_id) => {
    pushSegmentAnalyticsTrack('Listing Completed', {
      form_name: 'Listing',
      cwh_business: 'RV Rentals',
      listing_status: context?.initialData?.status?.value,
      goodSamMember: context?.isGoodSamMember,
      owner_id,
      fbc: getFbc(),
      fbp: getFbp(),
      ...getData(context?.initialData, context?.initialData?.listing_steps_completed, context)
    });
  }, []);

  return {
    trackSegmentGeneralInfoLoad,
    trackSegmentGeneralInfoListingStepViewed,
    trackSegmentGeneralInfoCompleted,
    trackSegmentRVDetailsLoad,
    trackSegmentRVDetailsCompleted,
    trackSegmentListingDetailsLoad,
    trackSegmentListingDetailsCompleted,
    trackSegmentPhotosLoad,
    trackSegmentPhotosCompleted,
    trackSegmentAmenitiesLoad,
    trackSegmentAmenitiesCompleted,
    trackSegmentLocationLoad,
    trackSegmentLocationCompleted,
    trackSegmentFeesLoad,
    trackSegmentFeesCompleted,
    trackSegmentOtherFeesLoad,
    trackSegmentOtherFeesCompleted,
    trackSegmentAdditionalInsuranceLoad,
    trackSegmentAdditionalInsuranceCompleted,
    trackSegmentAvailabilityLoad,
    trackSegmentAvailabilityCompleted,
    trackSegmentSubmitListing
  };
};
