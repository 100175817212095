import React, { useCallback, useEffect, useRef } from "react";
import { useCalculatePrice } from "./CalculatePrice/useCalculatePrice";
import { CALCULATE_PRICE_ERROR_CODES } from "constants/errorCodes";
import { BookingContent } from "./BookingContent";
import { useBookingSession } from "./BookingSession";
import { createCalculatePriceFetchData } from "./utils/createCalculatePriceFetchData";
import { getRentalDetailsPath } from "processes/Rentals/utils";
import { writeStorage } from "utils/storageFirstView";
import { TOAST_TYPE } from "constants/toastTypes";
import moment from "moment";
import { getFbp, getFbc } from "utils/segment-analytics/utils/facebookPixel";

function reducer(state, payload) {
  return {
    ...state,
    ...payload
  };
}

function getIsAllData(state) {
  return state.date?.departure && state.date?.return && state.fullAddress;
}

export const BookingContentProvider = ({ initialData, refetchRvInitialData }) => {
  const { rvId, isIMGlobalIntegrationIsEnabled } = initialData;
  const [bookingSession, updateBookingSession] = useBookingSession(rvId);
  const [price, onCalculatePrice] = useCalculatePrice(rvId);
  const validationRef = useRef(0);
  const departureDate = moment(bookingSession.date.departure);
  const returnDate = moment(bookingSession.date.return);
  const duration = returnDate.diff(departureDate, 'days');
  const state = {
    ...initialData,
    ...bookingSession,
    segmentData: {
      ...initialData.segmentData,
      location: bookingSession?.fullAddress,
      rental_type: bookingSession.rental_type === 1 ? 'standard' : 'delivery only',
      departure: moment(bookingSession.date.departure).format("M/D/YYYY"),
      return: moment(bookingSession.date.return).format("M/D/YYYY"),
      duration,
      fbp: getFbp(),
      fbc: getFbc()
    }
  };

  const dispatch = useCallback(
    (action) => {
      return updateBookingSession((t) => reducer(t, action));
    },
    [updateBookingSession]
  );

  const onRefetchRvInitialData = useCallback(
    () => {
      refetchRvInitialData();
    },
    [refetchRvInitialData]
  );

  useEffect(() => {
    if (validationRef.current === 0 && price.loading) {
      validationRef.current = 1;
    } else if (validationRef.current === 1 && !price.loading) {
      // check errors
      if (price.error) {
        console.log("reset temporary session because of error", price.errorMessage);
        updateBookingSession(null);
        updateBookingSession({ rental_type: state.rental_type, isShowDates: true });
      } else {

        dispatch({
          isShowDates: true,
          goodsamMembershipPlanId: state?.isClientGoodSamMember ? undefined : state?.goodsamMembershipPlanId
        });
      }
      validationRef.current = 2;
    } else if (price.error && price.errorCode === CALCULATE_PRICE_ERROR_CODES.UNAVAILABLE_DATES_IN_THE_SELECTED_PERIOD && validationRef.current === 2) {

      if (refetchRvInitialData) {
        updateBookingSession({
          isShowDates: true,
          rental_type: state.rental_type
        });

        onRefetchRvInitialData();
        validationRef.current = 3;
      }
    }
  }, [price, validationRef, updateBookingSession, state.rental_type, dispatch, refetchRvInitialData, rvId, state.allowStandardRental, state.allowDeliveryOnlyRental, state?.goodsamMembershipPlanId, state?.isClientGoodSamMember, onRefetchRvInitialData]);

  useEffect(() => {
    onCalculatePrice(createCalculatePriceFetchData(state));
    if (!state.locationData[1]?.fullAddress && !state.locationData[2]?.fullAddress) {
      dispatch({
        isShowDates: false
      });
    }
  }, [validationRef]);

  return (
    <BookingContent
      state={state}
      price={price}
      onCalculatePrice={onCalculatePrice}
      isAllData={getIsAllData(state)}
      dispatch={dispatch}
      isIMGlobalIntegrationIsEnabled={isIMGlobalIntegrationIsEnabled}
      onRefetchRvInitialData={onRefetchRvInitialData}
      onReserve={(rentalId) => {
        updateBookingSession(null);
        if (rentalId) {
          // go to the specific rental
          writeStorage(rentalId, TOAST_TYPE.BOOKING, true);
          window.location.href = getRentalDetailsPath(rentalId);
        } else {
          window.location.href = `/rentals`;
        }
      }}
    />
  );
};
