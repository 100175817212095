import { gql } from "@apollo/client";
import { fragments } from "../queries/rvQuery";

export const additionalInsuranceMutation = gql`
  ${fragments.generalInfo},
  ${fragments.rvDetails},
  ${fragments.listingDetails},
  ${fragments.images},
  ${fragments.amenities},
  ${fragments.location},
  ${fragments.rentalFees},
  ${fragments.otherFeesAndAddons},
  ${fragments.publishedListingValues},
  ${fragments.availability},
  ${fragments.availabilityCalender}
  
  mutation rvUpdate(
    $id: Int!
    $additional_insurances: [AdditionalInsurancePackageInput]
  ) {
    rv_update(
      step: "additional_insurance"
      id: $id
      additional_insurances: $additional_insurances
    ) {
      result {
        success
        errors {
          field
          message
        }
      }

      rv {
        id
        fuel_type {
          key
          value
        }
        listing_steps_completed
        current_miles
        minimum_daily_rate {
          cents_total
        }
        amenities {
          id
          name
          icon
        }
        fields_awaiting_moderation
        owner {
          id
          good_sam_membership_validation {
            key
            value
          }
        }
        class
        make
        model
        year
        length {
          feet_as_part
          inches_total
        }
        price {
          user_friendly
        }
        sleeps
        slide_outs
        weight
        stated_value {
          cents_total
        }
        daily_rate {
          dollars_as_part
          cents_total
        }
        status {
          key
          value
        }
        ...GeneralInfo,
        ...RvDetails,
        ...ListingDetails,
        ...Images,
        ...Amenities,
        ...Location,
        ...RentalFees,
        ...OtherFeesAndAddons
        ...PublishedListingValues
        ...Availability,
        ...AvailabilityCalender
      }
    }
  }
`;
