import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import icon from "./icon.svg";
import Button from "../../../../../components/Button";
import { searchIcon } from "../../../../../components/Icon";
import NoItemsPageNotice from "../../../../../components/NoItemsPageNotice/NoItemsPageNotice";
import { useRentalSegment } from "processes/Rentals/hooks/useRentalSegment";
import { useAddSegmentContext } from "hooks/useAddSegmentContext";

const NoItemsFound = () => {
  const history = useHistory();

  useAddSegmentContext();

  const { trackSegmentMyRentalsSearchRV } = useRentalSegment();

  const onSearchRv = useCallback(() => {
    trackSegmentMyRentalsSearchRV();
    history.push(`/search`);
  }, [history, trackSegmentMyRentalsSearchRV]);

  return (
    <div>
      <div className="b-xl-60" />
      <NoItemsPageNotice
        icon={icon}
        title="There are no rentals to display"
        description="Try changing your filters or search and reserve RV"
        button={(
          <Button
            secondary
            icon={searchIcon}
            iconPosition="after"
            onClick={onSearchRv}
          >
            Search RV
          </Button>
        )}
      />
      <div className="b-40 b-m-60" />
      <div className="b-0 b-xl-60" />
    </div>
  );
};

export default NoItemsFound;
