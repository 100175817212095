export const setFbpAndFbcCookie = () => {
  if (!document.cookie.split('; ').find(row => row.startsWith('_fbp='))) {
    const fbpValue = 'fb.1.' + Date.now() + '.' + Math.random().toString(36).substring(2, 15);
    document.cookie = `_fbp=${fbpValue}; path=/; domain=${window.location.hostname};`;
  }

  if (!document.cookie.split('; ').find(row => row.startsWith('_fbc='))) {
    const fbcValue = 'fb.1.' + Date.now() + '.' + Math.random().toString(36).substring(2, 15);
    document.cookie = `_fbc=${fbcValue}; path=/; domain=${window.location.hostname};`;
  }
};

export const getFbp = () => {
  const fbpCookie = document.cookie.split('; ').find(row => row.startsWith('_fbp='));
  return fbpCookie ? fbpCookie.split('=')[1] : '';
};

export const getFbc = () => {
  const fbcCookie = document.cookie.split('; ').find(row => row.startsWith('_fbc='));
  return fbcCookie ? fbcCookie.split('=')[1] : '';
};
