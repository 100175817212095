import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import { Header, Footer, AsideNav, StepsRouter, AsideInfo } from "./components";
import { useLocation } from "react-router-dom";
import { ListingContext } from "./ListingContext";
import Button from "../Button";
import { useLazyQuery, useMutation } from "@apollo/client";
import style from "./style.module.css";
import { defaultTo, F, filter, path, pathOr, pipe, pluck, uniq } from "ramda";
import Typography from "../Typography";
import { MatchMedia, useMatchMedia } from "../MatchMedia";
import { rvListingPreview } from "./queries/rvListingPreview";
import Modal from "../Modal/Modal";
import { SpinnerBack } from "../Spinner/Spinner";
import { rvPublish } from "./mutations/rvPublish";
import {
  extractServerErrors,
  extractSuccess,
  extractServerErrorsCode
} from "utils/extractErrors";
import { useHistory } from "react-router-dom";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { pushGoogleAnalytics } from "utils/google-analytics/push";
import steps from "components/Listing/components/steps.json";
import ReferralNotificationPopover from "components/ReferralNotificationPopover/ReferralNotificationPopover";
import { getCookie } from "utils/cookie";
import { handleListingSegment } from "components/Listing/utils/handleListingSegment";
import { VERIFICATION_ERROR_CODE_LIST, DEFAULT_DATA } from "components/VerificationCodeStepsModals/constants/verificationErrorCodes";
import VerificationCodeStepsModals from "components/VerificationCodeStepsModals/VerificationCodeStepsModals";
import { ContentLoader } from "components/ContentSection";
import { linksContentQuery } from "queries/contentQuery";
import { contentPredicate } from "utils/contentPredicate";
import { CONTENT_QUERY_LINK } from "constants/contentQuery";
import { useListingSegment } from "components/Listing/hooks/useListingSegment";

const extractRvListingPreviewSuccess = path(["rv_listing_preview", "result", "success"]);
const extractRvPublishSuccess = extractSuccess("rv_publish");
const extractRvPublishErrors = extractServerErrors("rv_publish");
const extractMutationErrorCode = extractServerErrorsCode("rv_publish");
const extractMutationErrors = extractServerErrors("rv_publish");
const extractStepsWithError = pipe(
  defaultTo([]),
  pluck("section"),
  uniq,
  filter((v) => v)
);

const ListingContentProvider = ({ context, updateContext, refetchData }) => {
  const history = useHistory();
  const ref = useRef(null);
  const [isShowNotificationPopover, setShowNotificationPopover] = useState(false);
  const [finalModalShow, setFinalModalShow] = useState(false);
  const [finalModalWasShowed, setFinalModalWasShowed] = useState(false);
  const [detailsModalActive, setDetailsModalActive] = useState(false);
  const [verificationStepsData, setVerificationStepsData] = useState(DEFAULT_DATA);
  const isFooterVisible = context?.isFooterVisible;
  const isRvInactiveStatus = context?.initialData?.status?.key === '5';
  const rvPublicUrl = context?.initialData?.public_url;
  const {
    trackSegmentSubmitListing
  } = useListingSegment({});

  useEffect(() => {
    if (context?.errors && context?.errors?.length > 0) {
      setShowNotificationPopover(true);
    }
  }, [context?.errors]);

  useEffect(() => {
    if (finalModalShow) {
      setFinalModalWasShowed(true);
    }
  }, [finalModalShow]);

  useEffect(() => {
    if (context && context.isListingPublished) {
      setFinalModalShow(false);
      setFinalModalWasShowed(false);
    }
  }, [context?.isListingPublished]);

  const { id: initialId, public_url } = context?.initialData ?? {};

  const [loadRvListingPreview, { data: rvListingPreviewResult, loading: rvPreviewLoading }] = useLazyQuery(
    rvListingPreview,
    {
      fetchPolicy: "no-cache"
    }
  );
  const [rvPublishMutation, rvPublishMutationResult] = useMutation(rvPublish);

  const isLargeScreen = useMatchMedia({ minWidth: 768 });
  const [isMobileNavMode, setIsMobileNavMode] = useState(!isLargeScreen);

  useEffect(() => {
    setIsMobileNavMode(!isLargeScreen);
  }, [isLargeScreen]);

  const classNames = classnames(
    style.listing,
    isMobileNavMode && ["listing_all-steps-view", style.listingAllStepsView],
    !isMobileNavMode && "listing_step-view",
    context && context.isListingPublished && style.listingFooterHidden
  );

  useEffect(() => {
    const errors = pathOr([], ["rv_listing_preview", "result", "errors"], rvListingPreviewResult);

    updateContext({
      errors
    });
  }, [rvListingPreviewResult, updateContext]);

  useEffect(() => {
    if (extractRvListingPreviewSuccess(rvListingPreviewResult)) {
      window.location.replace(public_url);
    }
  }, [public_url, rvListingPreviewResult]);

  const handleSave = useCallback(async () => {
    if (context.handleSave) await context.handleSave();
  }, [context]);

  const handlePreview = useCallback(async () => {
    if (!public_url || !initialId) return;

    await handleSave();

    loadRvListingPreview({
      variables: {
        id: initialId
      }
    });
  }, [handleSave, initialId, loadRvListingPreview, public_url]);

  const handlePublish = useCallback(async () => {
    if (!initialId) return;

    await handleSave();

    const variables = {
      id: initialId
    };

    const response = await rvPublishMutation({
      variables
    });

    if (extractRvPublishErrors(response)?.length > 0) {
      const errorCode = extractMutationErrorCode(response);

      if (VERIFICATION_ERROR_CODE_LIST.includes(errorCode)) {
        setVerificationStepsData({
          code: errorCode,
          variables
        });
      } else {
        const errors = extractMutationErrors(response);

        updateContext({
          errors
        });
      }
    }

    if (extractRvPublishSuccess(response)) {
      await refetchData();
      trackSegmentSubmitListing(context, context?.userId);
      history.push(`/listing/availability?id=${initialId}`);

      if (initialId) {
        pushGoogleAnalytics("listVehicleCompleted", {
          vehicleListingID: initialId
        });
      }
    }
  }, [initialId, handleSave, rvPublishMutation, updateContext, refetchData, trackSegmentSubmitListing, context, history]);

  const scrollToStart = useCallback(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  }, []);

  // Referral program

  const [showReferralAsideBlock, setShowReferralAsideBlock] = useState(false);

  const referralCookieTemplate = useMemo(
    () => `ReferralNotificationListing${context?.initialData?.id}Done`,
    [context?.initialData?.id]
  );

  const showReferralNotification = useMemo(
    () => showReferralAsideBlock || (context?.isListingPublished),
    [context?.isListingPublished, showReferralAsideBlock]
  );

  const onReferralNotificationClose = () => { setShowReferralAsideBlock(true); };

  if (!context || !context.isDataLoaded) return null;

  return (
    <div ref={ref} className={classNames}>
      <Header
        initialData={context.initialData}
        isMobileNavMode={isMobileNavMode}
        setIsMobileNavMode={setIsMobileNavMode}
        isListingPublished={context.isListingPublished}
        isRvCreated={context.isRvCreated}
        detailsModalActive={detailsModalActive}
        setDetailsModalActive={setDetailsModalActive}
      />
      <main className="container">
        <div className="row">
          <AsideNav
            isMobileNavMode={isMobileNavMode}
            setIsMobileNavMode={setIsMobileNavMode}
            stepsWithErrors={extractStepsWithError(context.errors)}
            setDetailsModalActive={setDetailsModalActive}
            showDetailsToggle={context.isListingPublished}
          />
          <section className="col-12 col-m-12 col-l-8 col-xl-6 ml-xl-64 _hidden-all-steps-view">
            <StepsRouter
              stepsCompleted={context.initialData.listing_steps_completed}
              listingPublished={context.isListingPublished}
              isRvCreated={context.isRvCreated}
              setFinalModalShow={setFinalModalShow}
              finalModalWasShowed={finalModalWasShowed}
              setFinalModalWasShowed={setFinalModalWasShowed}
              scrollToStart={scrollToStart}
            />
          </section>
          <AsideInfo
            className="_hidden-all-steps-view"
            showReferralNotification={showReferralNotification}
          />
        </div>

        {
          context.isListingPublished
          && !context.isListingModerated
          && !getCookie(referralCookieTemplate)
          && !isRvInactiveStatus
          && (
            <ReferralNotificationPopover
              title="listing_notification_title"
              text="listing_notification_text"
              acceptButton="listing_notification_accept_button"
              declineButton="listing_notification_decline_button"
              referralCookieTemplate={referralCookieTemplate}
              onClose={onReferralNotificationClose}
            />
          )
        }
      </main>
      <Footer
        className={classnames(
          !context.isListingPublished && "_visible-all-steps-view",
          context.isListingPublished && isFooterVisible && "_visible-step-view",
          !isFooterVisible && 'dn dn-m'
        )}
      >
        {context.errors && context.errors.length > 0 && (
          <MatchMedia mediaQuery="(min-width: 768px)">
            <NotificationPopover
              show={isShowNotificationPopover}
              status="error"
              text="Please complete all sections"
              bottomIndent={{
                hasIndent: isFooterVisible,
                size: 'Xlarge'
              }}
              onClose={() => {
                setShowNotificationPopover(false);
                updateContext({
                  ...context,
                  errors: []
                });
              }}
            />
          </MatchMedia>
        )}
        {!context.isListingPublished && (
          <>
            <div className="btn-wrap mr-16 mr-l-24 mr-xxl-32">
              <Button
                href={rvPublicUrl}
                target="_blank"
                noopener="true"
                norefferer="true"
                secondary
                label="Preview listing"
                id="previewListing"
                disabled={!context.isRvCreated}
              />
            </div>
            <div className="btn-wrap">
              <Button label="Submit listing" onClick={handlePublish} disabled={!context.isRvCreated} />
            </div>
          </>
        )}
        {context.isListingPublished && <Button label="Save changes" onClick={handleSave} />}
      </Footer>
      <Modal
        show={finalModalShow}
        onClose={() => setFinalModalShow(false)}
        modalWrapClassnames="mdl-wrap--full-screen-less-768"
        modalClassnames="mdl--simple mdl--full-screen-less-768"
      >
        <Typography variant="headline" size="s">
          Almost done!
        </Typography>
        <Typography variant="body" size="l">
          Time to preview or submit your listing. Once submitted, a listing coach will review and contact you with any
          questions. Listings can take up to 48 hours to be approved and show in search results.
        </Typography>
        <div className="btn-group">
          <div className="btn-wrap t-16 t-m-20 b-16 b-m-20">
            <Button
              secondary
              label="Preview listing"
              onClick={handlePreview}
              id="previewListing"
            />
          </div>
          <div className="btn-wrap t-16 t-m-20 b-16 b-m-20">
            <Button label="Submit listing" onClick={handlePublish} />
          </div>
        </div>
      </Modal>

      <VerificationCodeStepsModals
        data={verificationStepsData}
        onResetData={setVerificationStepsData}
        onRefetchMutation={handlePublish}
      />
      {(rvPreviewLoading || rvPublishMutationResult.loading) && <SpinnerBack />}
    </div>
  );
};

export const Listing = () => {
  return (
    <ListingContext.Consumer>
      {(context) => (
        <ContentLoader
          query={linksContentQuery}
          predicate={contentPredicate([CONTENT_QUERY_LINK])}
          render={() => (
            <ListingContentProvider
              context={context[0]}
              updateContext={context[1]}
              refetchData={context[2]}
            />
          )}
        />
      )}
    </ListingContext.Consumer>
  );
};
