import { convertCents } from "utils/convertCents";

export const compareData = (obj1, obj2) => {
  let result = { ...obj2 };
  let hasChanges = false;

  Object.keys(obj1).forEach(key => {
    if (typeof obj1[key] !== "object" && obj1[key] !== obj2[key]) {
      result[`${key}_orig`] = obj1[key];
      hasChanges = true;
    }
  });

  return { result, hasChanges };
};

export const getInsurances = (insurances) => {
  return insurances
    .filter(insurance => insurance.required && insurance.packages.length > 0)
    .map(insurance => {
      const selectedPackage = insurance.packages[0];
      return {
        insurance_name: insurance.name,
        insurance_pack_name: selectedPackage.name,
        insurance_cost: selectedPackage.price
      };
    });
};

export const updateInsuranceRequired = (firstArray, secondArray) => {
  const insuranceMap = new Map(firstArray.map(item => [item.id, item.required]));

  return secondArray.map(insurance => {
    if (insuranceMap.has(insurance.id)) {
      return { ...insurance, required: insuranceMap.get(insurance.id) };
    }
    return insurance;
  });
};

export const transformAmenities = (selectedAmenities, allAmenities) => {
  return allAmenities.reduce((acc, category) => {
    const selectedItems = category.items.filter(item =>
      selectedAmenities.some(selected => selected.id === item.id)
    );

    if (selectedItems.length > 0) {
      acc.push({
        name: category.category,
        selected: selectedItems.map(item => item.name)
      });
    }

    return acc;
  }, []);
};

export const getData = (data, steps = [], { insurances, rvAmenities }) => {
  return steps.reduce((acc, step) => {
    switch (step) {
    case 'general_info':
      return {
        ...acc,
        asset_year: data?.year,
        asset_make: data?.make,
        asset_brand: data?.manufacturer,
        asset_model: data?.model,
        asset_class: data?.class
      };
    case 'rv_details':
      return {
        ...acc,
        asset_length: data?.length?.feet_as_part,
        asset_weight: data?.weight,
        asset_slideouts: data?.slide_outs,
        asset_sleeps: data?.sleeps,
        asset_fuel_type: data?.fuel_type?.value,
        asset_seatbelts: data?.seatbelts,
        electric_service: data?.electric_service,
        asset_fresh_water: data?.fresh_water,
        asset_black_water: data?.black_water,
        asset_gray_water:data?.gray_water,
        asset_propane: data?.propane,
        asset_vin: data?.vin,
        asset_stated_value: Number(convertCents(data?.stated_value?.cents_total)),
        asset_miles: data?.current_miles
      };
    case 'listing_details':
      return {
        ...acc,
        listing_title: data?.listing_title,
        listing_description: data?.listing_description,
        listing_includes: data?.what_is_included
      };
    case 'photos':
      return {
        ...acc,
        image_count: data?.images?.length
      };
    case 'amenities':
      const amenities = transformAmenities(data?.amenities, rvAmenities);
      return {
        ...acc,
        ...((amenities?.length > 0) && { amenities })
      };
    case 'location':
      const specific_location = data?.specific_delivery_locations?.map(value =>  {
        return {
          name: value.location.full_address,
          delivery_fee: Number(convertCents(value.delivery_fee.cents_total))
        };
      });

      const dor_specific_location = data?.dor_specific_delivery_locations.map(value =>  {
        return {
          name: value.location.full_address,
          delivery_fee: Number(convertCents(value.delivery_fee.cents_total))
        };
      });

      return {
        ...acc,
        asset_location: data?.location?.full_address,
        allow_standard_delivery: data?.allow_standard_rental,
        ...(data?.delivery_option && {
          free_delivery_max_distance_miles: data?.free_delivery,
          paid_delivery_max_distance_miles: data?.paid_delivery,
          ...(data?.delivery_price_type === 1 && {
            delivery_cost_per_mile: Number(convertCents(data?.cost_per_mile?.cents_total)),
            delivery_min_fee: Number(convertCents(data?.minimum_fee?.cents_total))
          }),
          ...(data?.delivery_price_type === 2 && {
            delivery_flat_price: Number(convertCents(data?.flat_delivery_price?.cents_total))
          })
        }),
        ...((specific_location?.length > 0) && { specific_location }),
        allow_dor_delivery: data?.allow_delivery_only_rental,
        ...(data?.dor_delivery_option && {
          dor_free_delivery_max_distance_miles: data?.dor_free_delivery,
          dor_paid_delivery_max_distance_miles: data?.dor_paid_delivery,
          ...(data?.dor_delivery_price_type === 1 && {
            dor_delivery_cost_per_mile: Number(convertCents(data?.dor_cost_per_mile?.cents_total)),
            dor_delivery_min_fee: Number(convertCents(data?.dor_minimum_fee?.cents_total))
          }),
          ...(data?.dor_delivery_price_type === 2 && {
            dor_delivery_flat_price: Number(convertCents(data?.dor_flat_delivery_price?.cents_total))
          })
        }),
        ...((dor_specific_location?.length > 0) && { dor_specific_location })
      };
    case 'fees':
      const multiday_discounts = data?.discounts?.map(value => {
        return {
          from: value.from,
          percent: value.discount
        };
      });
      return {
        ...acc,
        rental_nights_min: data?.minimum_duration,
        rate_per_night: Number(convertCents(data?.daily_rate?.cents_total)),
        instant_rental: data?.instant_rentals,
        ...((multiday_discounts?.length > 0) && { multiday_discounts }),
        deposit: Number(convertCents(data?.security_deposit?.cents_total))
      };
    case 'add_ons':
      const addons = data?.add_ons?.map(value => value.name);
      return {
        ...acc,
        mileage_rates: data?.limited_daily_mileage ? 'Set my own rates' : 'unlimited',
        ...(data?.limited_daily_mileage && {
          miles_included: data?.miles_included,
          milage_overage_rate: Number(convertCents(data?.mileage_overage_rate?.cents_total))
        }),
        generator_rates: data.limited_generator_hours ? 'Set my own rates' : 'unlimited',
        ...(data?.limited_generator_hours && {
          generator_hours_included: data?.generator_hours_included,
          generator_hours_overage_rate: Number(convertCents(data?.generator_overage_rate?.cents_total))
        }),
        mandatory_cleaning_fee: Number(convertCents(data?.cleaning_fee?.cents_total)),
        mandatory_disposal_fee: Number(convertCents(data?.disposal_fee?.cents_total)),
        ...((data?.add_ons?.length > 0) && { addons })
      };
    case 'additional_insurance':
      return {
        ...acc,
        ...((getInsurances(insurances)?.length > 0) && { insurances: getInsurances(insurances) })
      };
    default:
      return acc;
    }
  }, {});
};

export const processListingData = (prevRv, nextRv, listing_steps_completed, context, nextContext) => {
  const prevData = getData(prevRv, listing_steps_completed, context);
  const nextData = getData(nextRv, nextRv?.listing_steps_completed, nextContext ? nextContext : context);
  let resultData, isChanges;

  if (context?.isListingPublished) {
    const { result, hasChanges } = compareData(prevData, nextData);
    resultData = result;
    isChanges = hasChanges;
  } else {
    resultData = nextData;
  }

  const eventType = isChanges ? 'Listing Updated' : 'Listing Step Completed';
  return { resultData, eventType };
};
