import Button from "components/Button";
import React from "react";
import { dateFormat } from "utils/dayPickerDataFormatting";
import classes from "./BookingWidgetBar.module.css";
import { useBottomBarSize } from "../../../../hooks/useBottomBarSize";
import { formatInteger } from "utils/formatNumber";

export const BookingWidgetBar = (props) => {
  const {
    minimumDuration,
    priceWidget,
    totalWidget,
    discountWidget,
    date,
    setIsShowModalBookingWidget
  } = props;

  const departureDate = dateFormat(date.departure);
  const returnDate = dateFormat(date.return);

  const barRef = useBottomBarSize();
  const newTotalWidget = totalWidget && formatInteger(totalWidget);
  const newPriceWidget = priceWidget && formatInteger(priceWidget);

  return (
    <div className={`${classes.bookingWidgetBar}`} ref={barRef}>
      <div className={`${classes.bookingWidgetBarLeftColumn}`}>
        <div className={`${classes.bookingWidgetBarDiscountContainer}`}>
          <div className={`${classes.bookingWidgetBarDiscountInfo}`}>
            {priceWidget && (
              <div className={`${classes.bookingWidgetBarPrice}`}>
                ${newPriceWidget}
              </div>
            )}
            <div className={`${classes.bookingWidgetBarTotal}`}>
              ${newTotalWidget}
              <span className={`${classes.bookingWidgetBarTotalText}`}>
                per night
              </span>
              <span className={`${classes.bookingWidgetBarTotalTextMin}`}>
                /night
              </span>
            </div>
            {discountWidget && (
              <div className={`${classes.bookingWidgetBarDiscount}`}>
                -{discountWidget}%
              </div>
            )}
          </div>
          <div className={`${classes.bookingWidgetBarDuration}`}>
            {minimumDuration} nights minimum
          </div>
        </div>
      </div>

      <div className={`${classes.bookingWidgetBarRightColumn}`}>
        <div className={`${classes.bookingWidgetBarDateContainer}`}>
          <h3 className={`${classes.bookingWidgetBarDateTitle}`}>
            Departure/Return
          </h3>
          <div className={`${classes.bookingWidgetBarDateInfo}`}>
            {departureDate} - {returnDate}
          </div>
        </div>

        <div className={`${classes.bookingWidgetBarButtonContainer}`}>
          <Button
            onClick={() => {
              setIsShowModalBookingWidget(true);
            }}
            className={`${classes.bookingWidgetBarButton}`}
            label="Reserve RV"
          />
        </div>
      </div>
    </div>
  );
};
