import { gql } from "@apollo/client";
import { fragments } from "../queries/rvQuery";

export const listingDetailsMutation = gql`
  ${fragments.generalInfo},
  ${fragments.rvDetails},
  ${fragments.listingDetails},
  ${fragments.images},
  ${fragments.amenities},
  ${fragments.location},
  ${fragments.rentalFees},
  ${fragments.otherFeesAndAddons},
  ${fragments.publishedListingValues},
  ${fragments.availability},
  ${fragments.availabilityCalender}

  mutation rvUpdate(
    $id: Int
    $listing_title: String!
    $listing_description: String!
    $what_is_included: String!
    $recommendations: String!
  ) {
    rv_update(
      step: "listing_details"
      id: $id
      listing_title: $listing_title
      listing_description: $listing_description
      what_is_included: $what_is_included
      recommendations: $recommendations
    ) {
      result {
        success
        errors {
          field
          message
        }
      }
      rv {
        id
        fields_awaiting_moderation
        owner {
          id
          good_sam_membership_validation {
            key
            value
          }
        }
        class
        make
        model
        year
        length {
          feet_as_part
          inches_total
        }
        price {
          user_friendly
        }
        sleeps
        slide_outs
        weight
        stated_value {
          cents_total
        }
        daily_rate {
          dollars_as_part
          cents_total
        }
        status {
          key
          value
        }
        fuel_type {
          key
          value
        }
        listing_steps_completed
        current_miles
        minimum_daily_rate {
          cents_total
        }
        amenities {
          id
          name
          icon
        }
        ...GeneralInfo,
        ...RvDetails,
        ...ListingDetails,
        ...Images,
        ...Amenities,
        ...Location,
        ...RentalFees,
        ...OtherFeesAndAddons
        ...PublishedListingValues
        ...Availability,
        ...AvailabilityCalender
      }
    }
  }
`;
