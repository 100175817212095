import React, { useCallback, useEffect, useRef } from "react";
import { defaultTo, filter, path, pick, pipe, map } from "ramda";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { ListingDetailsForm } from "./ListingDetailsForm";
import { ListingContext } from "../../ListingContext";
import { listingDetailsMutation } from "../../mutations/listingDetailsMutation";
import { filterInitialData } from "../filterInitialData";
import {
  extractServerErrors,
  extractSuccess
} from "../../../../utils/extractErrors";
import { SpinnerBack } from "../../../Spinner/Spinner";
import { ContentLoader } from "components/ContentSection";
import { listingDetailsSectionContentQuery } from "components/Listing/queries/listingSectionContentQuery";
import { pushGoogleAnalytics } from "utils/google-analytics/push";
import { handleListingSegment } from "components/Listing/utils/handleListingSegment";
import { useListingSegment } from "components/Listing/hooks/useListingSegment";
import { userIdQuery } from "queries/userIdQuery";

const filterInitialListingDetails = filterInitialData([
  "listing_title",
  "listing_description",
  "what_is_included",
  "recommendations"
]);

const extractListingDetailsErrors = extractServerErrors("rv_update");
const extractListingDetailsSuccess = extractSuccess("rv_update");
const extractRv = path(["data", "rv_update", "rv"]);

const filterPublishErrorsBySection = pipe(
  defaultTo([]),
  filter((v) => v.section === "listing_details"),
  map(pick(["field", "message"]))
);
const cleanSectionErrors = pipe(
  defaultTo([]),
  filter((v) => v.section !== "listing_details")
);

const ListingDetailsDataProvider = ({
  context,
  updateContext,
  currStep,
  prevStep,
  nextStep
}) => {
  const isSegmentSent = useRef(null);
  const { id: initialId } = context.initialData;
  const history = useHistory();
  const [action, result] = useMutation(listingDetailsMutation);
  const {
    trackSegmentListingDetailsLoad,
    trackSegmentListingDetailsCompleted
  } = useListingSegment(context);

  const handleSave = useCallback(
    async (values, { toNextStep = true } = {}) => {
      const response = await action({
        variables: {
          ...values,
          id: initialId
        }
      });

      if (extractListingDetailsSuccess(response)) {
        const rv = extractRv(response);

        if (rv) {
          pushGoogleAnalytics("listVehicleListingDetails");
          trackSegmentListingDetailsCompleted(context?.initialData, rv, context?.userId);

          updateContext({
            initialData: {
              ...context.initialData,
              ...rv
            },
            errors: cleanSectionErrors(context.errors)
          });

          if (nextStep && toNextStep) {
            history.push(`/listing/${nextStep.path}?id=${rv.id}`, {
              prevStep: currStep
            });
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [action, initialId, context.errors, updateContext, nextStep, history, currStep, context?.initialData, context?.userId]
  );

  const handleBack = useCallback(() => {
    if (prevStep) {
      history.push(`/listing/${prevStep.path}?id=${initialId}`);
    }
  }, [history, initialId, prevStep]);

  const handleLater = useCallback(() => {
    if (nextStep) {
      history.push(`/listing/${nextStep.path}?id=${initialId}`);
    }
  }, [history, initialId, nextStep]);

  const updateContextSaveHandler = useCallback(
    (cb) => {
      updateContext({
        handleSave: cb
      });
    },
    [updateContext]
  );

  useEffect(() => {
    if (!isSegmentSent.current && context?.userId) {
      trackSegmentListingDetailsLoad(context?.userId);
      isSegmentSent.current = true;
    }
  }, [trackSegmentListingDetailsLoad, context?.userId]);

  const publishErrors = filterPublishErrorsBySection(context.errors);

  const stepErrors = extractListingDetailsErrors(result);

  return (
    <>
      <ListingDetailsForm
        initialData={filterInitialListingDetails(context.initialData)}
        requestErrors={publishErrors.length ? publishErrors : stepErrors}
        onBack={handleBack}
        onLater={handleLater}
        onSave={handleSave}
        loading={result.loading}
        updateContextSaveHandler={updateContextSaveHandler}
        listingPublished={context.isListingPublished}
        prevStep={prevStep}
        nextStep={nextStep}
      />
      {result.loading && <SpinnerBack />}
    </>
  );
};

function everyKey(keys) {
  return (data) => keys.every((k) => data[k]);
}

const listingDetailsSectionContentPredicate = everyKey([
  "listingDetailsSectionContent"
]);
export const ListingDetails = ({ currStep, prevStep, nextStep }) => {
  return (
    <ListingContext.Consumer>
      {(context) => (
        <ContentLoader
          query={listingDetailsSectionContentQuery}
          predicate={listingDetailsSectionContentPredicate}
          render={() => (
            <ListingDetailsDataProvider
              context={context[0]}
              updateContext={context[1]}
              currStep={currStep}
              prevStep={prevStep}
              nextStep={nextStep}
            />
          )}
        />
      )}
    </ListingContext.Consumer>
  );
};
