import { useCallback } from "react";
import { trackSegmentBooking } from "processes/Booking/utils/trackSegmentBooking";
import { extractInsurances, extractAddOns, extractGSM } from "processes/Booking/components/BookingModal/utils/segmentUtils";
import { convertCents } from "utils/convertCents";

export const useBookingStepSegment = (mainSegment, addOns, insurancesData, gsmData, price) => {
  const addons = extractAddOns(addOns);
  const { insurances, total_coverage } = extractInsurances(insurancesData);
  const products = extractGSM(gsmData);
  const due_rental_is_confirmed = price?.payments?.map((pr) => ({
    amount: Number(convertCents(pr?.amount?.cents_total)),
    name: pr?.name
  }));

  const trackSegmentAddonsLoad = useCallback(() => {
    trackSegmentBooking('Checkout Step Viewed', {
      ...mainSegment,
      step: 1,
      step_name: "Add-ons"
    });
  }, [mainSegment]);

  const trackSegmentAddonsCompleted = useCallback(() => {
    trackSegmentBooking('Checkout Step Completed', {
      ...mainSegment,
      step: 1,
      step_name: "Add-ons",
      ...((addons?.length > 0) && { addons })
    });
  }, [mainSegment, addons]);


  const trackSegmentAdditionalInsuranceLoad = useCallback(() => {
    trackSegmentBooking('Checkout Step Viewed', {
      ...mainSegment,
      step: 2,
      step_name: "Additional insurance",
      ...((addons?.length > 0) && { addons })
    });
  }, [mainSegment, addons]);

  const trackSegmentAdditionalInsuranceCompleted = useCallback(() => {
    trackSegmentBooking('Checkout Step Completed', {
      ...mainSegment,
      step: 2,
      step_name: "Additional insurance",
      ...((addons?.length > 0) && { addons }),
      ...((insurances?.length > 0) && { insurances }),
      ...((insurances?.length > 0) && { total_coverage })
    });
  }, [mainSegment, addons, insurances, total_coverage]);


  const trackSegmentPaymentMethodLoad = useCallback(() => {
    trackSegmentBooking('Checkout Step Viewed', {
      ...mainSegment,
      step: 3,
      step_name: "Payment method",
      ...((addons?.length > 0) && { addons }),
      ...((insurances?.length > 0) && { insurances }),
      ...((insurances?.length > 0) && { total_coverage })
    });
  }, [mainSegment, addons, insurances, total_coverage]);

  const trackSegmentPaymentMethodCompleted = useCallback(() => {
    trackSegmentBooking('Checkout Step Completed', {
      ...mainSegment,
      step: 3,
      step_name: "Payment method",
      ...((addons?.length > 0) && { addons }),
      ...((insurances?.length > 0) && { insurances }),
      ...((insurances?.length > 0) && { total_coverage })
    });
  }, [mainSegment, addons, insurances, total_coverage]);

  const trackSegmentPaymentOptionsLoad = useCallback(() => {
    trackSegmentBooking('Checkout Step Viewed', {
      ...mainSegment,
      step: 4,
      step_name: "Payment options",
      ...((addons?.length > 0) && { addons }),
      ...((insurances?.length > 0) && { insurances }),
      ...((insurances?.length > 0) && { total_coverage })
    });
  }, [mainSegment, addons, insurances, total_coverage]);

  const trackSegmentPaymentOptionsCompleted = useCallback(() => {
    trackSegmentBooking('Checkout Step Completed', {
      ...mainSegment,
      step: 4,
      step_name: "Payment options",
      total_booking_amt: Number(convertCents(price?.payment_total?.cents_total)),
      due_rental_is_confirmed,
      gs_membership_fee: Number(convertCents(price?.good_sam_membership_fee?.cents_total)),
      rental_fee: Number(convertCents(price?.rental_fee?.cents_total)),
      coupon: Number(convertCents(price?.coupon_discount?.cents_total)),
      cleaning_fee: Number(convertCents(price?.cleaning_fee?.cents_total)),
      disposal_fee: Number(convertCents(price?.disposal_fee?.cents_total)),
      delivery_fee: Number(convertCents(price?.delivery_fee?.cents_total)),
      booking_fee: Number(convertCents(price?.booking_fee?.cents_total)),
      gs_member_savings: Number(convertCents(price?.good_sam_membership_discount?.cents_total)),
      rental_protection_plan: price?.insurance_package_name?.toLowerCase(),
      rental_protection_amt: Number(convertCents(price?.insurance_fee?.cents_total)),
      taxes: Number(convertCents(price?.taxes?.total?.cents_total)),
      security_deposit: Number(convertCents(price?.security_deposit?.cents_total)),
      ...((addons?.length > 0) && { addons }),
      ...((insurances?.length > 0) && { insurances }),
      ...((insurances?.length > 0) && { total_coverage }),
      ...(products?.length > 0 && { products })
    });
  }, [mainSegment, addons, insurances, total_coverage, products, price, due_rental_is_confirmed]);

  const trackSegmentConfirmAndPay = useCallback(() => {
    trackSegmentBooking('Order Completed', {
      ...mainSegment,
      total_booking_amt: Number(convertCents(price?.payment_total?.cents_total)),
      due_rental_is_confirmed,
      gs_membership_fee: Number(convertCents(price?.good_sam_membership_fee?.cents_total)),
      rental_fee: Number(convertCents(price?.rental_fee?.cents_total)),
      coupon: Number(convertCents(price?.coupon_discount?.cents_total)),
      cleaning_fee: Number(convertCents(price?.cleaning_fee?.cents_total)),
      disposal_fee: Number(convertCents(price?.disposal_fee?.cents_total)),
      delivery_fee: Number(convertCents(price?.delivery_fee?.cents_total)),
      booking_fee: Number(convertCents(price?.booking_fee?.cents_total)),
      gs_member_savings: Number(convertCents(price?.good_sam_membership_discount?.cents_total)),
      rental_protection_plan: price?.insurance_package_name?.toLowerCase(),
      rental_protection_amt: Number(convertCents(price?.insurance_fee?.cents_total)),
      taxes: Number(convertCents(price?.taxes?.total?.cents_total)),
      security_deposit: Number(convertCents(price?.security_deposit?.cents_total)),
      ...((addons?.length > 0) && { addons }),
      ...((insurances?.length > 0) && { insurances }),
      ...((insurances?.length > 0) && { total_coverage }),
      ...(products?.length > 0 && { products })
    });
  }, [mainSegment, addons, insurances, total_coverage, products, price, due_rental_is_confirmed]);

  const trackSegmentNextStep = useCallback((currentStep, nextStep) => {
    switch (currentStep) {
    case 1:
      trackSegmentAddonsCompleted();
      break;
    case 2:
      trackSegmentAdditionalInsuranceCompleted();
      break;
    case 3:
      trackSegmentPaymentMethodCompleted();
      break;
    default:
      return null;
    }

    switch (nextStep) {
    case 2:
      trackSegmentAdditionalInsuranceLoad();
      break;
    case 3:
      trackSegmentPaymentMethodLoad();
      break;
    case 4:
      trackSegmentPaymentOptionsLoad();
      break;
    default:
      return null;
    }
  }, [
    trackSegmentAddonsCompleted,
    trackSegmentAdditionalInsuranceCompleted,
    trackSegmentPaymentMethodCompleted,
    trackSegmentAdditionalInsuranceLoad,
    trackSegmentPaymentMethodLoad,
    trackSegmentPaymentOptionsLoad
  ]);

  const trackSegmentStep = useCallback((step) => {
    switch (step) {
    case 1:
      trackSegmentAddonsLoad();
      break;
    case 2:
      trackSegmentAdditionalInsuranceLoad();
      break;
    case 3:
      trackSegmentPaymentMethodLoad();
      break;
    case 4:
      trackSegmentPaymentOptionsLoad();
      break;
    default:
      return null;
    }
  }, [trackSegmentAdditionalInsuranceLoad, trackSegmentPaymentMethodLoad, trackSegmentPaymentOptionsLoad, trackSegmentAddonsLoad]);

  return {
    trackSegmentAddonsLoad,
    trackSegmentAddonsCompleted,
    trackSegmentAdditionalInsuranceLoad,
    trackSegmentAdditionalInsuranceCompleted,
    trackSegmentPaymentMethodLoad,
    trackSegmentPaymentMethodCompleted,
    trackSegmentPaymentOptionsLoad,
    trackSegmentPaymentOptionsCompleted,
    trackSegmentConfirmAndPay,
    trackSegmentNextStep,
    trackSegmentStep
  };
};
