import React, { useCallback, useEffect, useState, useRef } from "react";
import { useRequestErrors } from "../../../../hooks/useRequestErrors";
import { useQuery } from "@apollo/client";
import Button from "../../../Button";
import { AdditionalInsuranceList } from "components/AdditionalInsuranceList";
import ProTipsCollapse from "../../../ProTipsCollapse";
import {
  Button as ButtonContent,
  Text
} from "components/ContentSection/elements";
import { getInitialAdditionalInsuranceData, getTotalInsurances } from "components/AdditionalInsuranceList/utils";
import { SpinnerBack } from "components/Spinner/Spinner";
import useFooterVisible from "components/Listing/hooks/useFooterVisible";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import { ADD_INSURANCE_LIST_MODES } from "components/AdditionalInsuranceList/constants";
import { rvAdditionalInsuranceQuery } from "components/Listing/steps/AdditionalInsurance/queries/rvAdditionalInsuranceQuery";
import { TextItem } from "components/TextItem";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import cn from 'classnames';
import classes from "./AdditionalInsurance.module.css";
import { updateInsuranceRequired } from "../../utils/listingSegmentUtils";

export const AdditionalInsuranceContentLoader = ({
  initialData,
  onBack,
  onLater,
  onSave,
  loading,
  context,
  updateContext,
  updateContextSaveHandler,
  listingPublished,
  requestErrors,
  onResetError,
  prevStep,
  nextStep
}) => {
  const { id: initialId } = initialData;
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const [addInsuranceList, setAddInsuranceList] = useState({});
  const additionalInsuranceLoaded = useRef(false);
  const [selectedPackagesAdditionalInsurance, setSelectedPackagesAdditionalInsurance] = useState({});

  const [isDirty, setIsDirty] = useState(false);
  const [globalError] = useRequestErrors(requestErrors);
  useFooterVisible(isDirty);

  const { loading: rvAdditionalInsuranceLoading, data: rvAdditionalInsuranceData } = useQuery(rvAdditionalInsuranceQuery, {
    variables: {
      rv: initialId
    },
    fetchPolicy: "no-cache"
  });

  const handleSave = useCallback(() => {
    onSave(getTotalInsurances(selectedPackagesAdditionalInsurance, addInsuranceList));
  }, [addInsuranceList, onSave, selectedPackagesAdditionalInsurance]);

  useEffect(() => {
    updateContextSaveHandler(() => onSave(getTotalInsurances(selectedPackagesAdditionalInsurance, addInsuranceList), { toNextStep: false }));
  }, [addInsuranceList, handleSave, onSave, selectedPackagesAdditionalInsurance, updateContextSaveHandler]);

  useEffect(() => {
    if (rvAdditionalInsuranceData?.rv_additional_insurance?.insurances) {
      setSelectedPackagesAdditionalInsurance(getInitialAdditionalInsuranceData(rvAdditionalInsuranceData?.rv_additional_insurance, ADD_INSURANCE_LIST_MODES.SETTINGS));
      setAddInsuranceList(rvAdditionalInsuranceData?.rv_additional_insurance);
      updateContext({
        insurances: rvAdditionalInsuranceData?.rv_additional_insurance?.insurances
      });
    }
  }, [rvAdditionalInsuranceData?.rv_additional_insurance, updateContext]);

  useEffect(() => {
    if (additionalInsuranceLoaded?.current) {
      setIsDirty((num) => num + 1);
    } else if (!additionalInsuranceLoaded?.current && selectedPackagesAdditionalInsurance.insurancesIds) {
      additionalInsuranceLoaded.current = true;
    }
  }, [selectedPackagesAdditionalInsurance]);

  return (
    <div>
      <div className="row">
        <div className="col b-l-8 b-xl-0">
          <Text
            id="step_title"
            section="additionalInsuranceSectionContent"
            component="h4"
            variant="title"
            weight="bold"
          />
          <ProTipsCollapse className="t-16 b-12" mediaParams="767px" />
        </div>
      </div>
      <div
        className={cn(
          "mb-28 b-m-2 mt-m-8 mt-l-0 mb-l-28 b-l-2",
          classes.descContainer
        )}
      >
        <TextItem
          plainStyle={classes.descPlainStyle}
          htmlStyle={classes.descHtmlStyle}
          data={addInsuranceList?.description}
        />
      </div>
      <div className="b-20 b-m-0">
        <AdditionalInsuranceList
          insurances={addInsuranceList?.insurances}
          selectedPackages={selectedPackagesAdditionalInsurance}
          setSelectedPackages={setSelectedPackagesAdditionalInsurance}
          mode={ADD_INSURANCE_LIST_MODES.SETTINGS}
        />
      </div>

      {globalError?.length > 0 && (
        <NotificationPopover
          show
          status="error"
          text={globalError}
          onClose={() => {
            onResetError([]);
          }}
          bottomIndent={{
            hasIndent: true,
            size: 'small'
          }}
        />
      )}
      {rvAdditionalInsuranceLoading && <SpinnerBack />}

      {!listingPublished && (
        <div className="row">
          { nextStep && isSmallDevice && (
            <div className="col-9 t-20 b-36">
              <ButtonContent
                id="skip_on_button"
                section="additionalInsuranceSectionContent"
                disabled={loading}
                onClick={onLater}
                secondary
              />
            </div>
          )}
          <div className="btn-group btn-group--center btn-group--m-start t-16 t-m-40 b-16 b-s-20 btn-group_xs-fixed">
            {prevStep && (
              <ButtonContent
                id="previous_button"
                section="additionalInsuranceSectionContent"
                onClick={onBack}
                disabled={loading}
                secondary
              />
            )}
            {nextStep && !isSmallDevice && (
              <ButtonContent
                id="skip_on_button"
                section="additionalInsuranceSectionContent"
                disabled={loading}
                onClick={onLater}
                secondary
              />
            )}
            {nextStep && (
              <ButtonContent
                id="save_and_next_button"
                section="additionalInsuranceSectionContent"
                onClick={handleSave}
                disabled={loading}
              />
            )}

            {!nextStep && (
              <Button onClick={handleSave} disabled={loading}>
                Save
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

AdditionalInsuranceContentLoader.propTypes = {

};

AdditionalInsuranceContentLoader.defaultProps = {

};
